import About from './components/About';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import Hero from './components/Hero';
import HeroBanner from './components/HeroBanner';
import Reviews from './components/Reviews';
// import Services from './components/Services';

const LandingPage: React.FC = () => {
  return (
    <div
      style={{
        paddingTop: '50px',
        display: 'flex',
        backgroundColor: '#F4F4F4',
        flexDirection: 'column',
      }}
    >
      <div style={{ maxWidth: '1400px', margin: 'auto' }}>
        <Hero />
        <About />
        {/* -- always disabled <Services /> */}
        <Reviews />
        <HeroBanner />
        <FAQ />
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
