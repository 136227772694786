import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ADDITIONAL_SERVICES } from '@web-app/common';

export interface FilePreview {
  url: string;
  type: string;
  object: File;
}

export interface ServiceAdjustState {
  serviceId?: number; // when editing existing service only

  // base
  name?: string;
  description?: string;

  // contact
  phone?: string;
  email?: string;
  city?: string;
  address?: string;

  // files
  filePreviews?: FilePreview[];

  // price
  price?: number;
  customPrice?: boolean;

  // additional services
  selectedService?: ADDITIONAL_SERVICES[];

  initialData: {
    serviceId?: number; // when editing existing service only

    // base
    name?: string;
    description?: string;

    // contact
    phone?: string;
    email?: string;
    city?: string;
    address?: string;

    // files
    filePreviews?: FilePreview[];

    // price
    price?: number;
    customPrice?: boolean;

    // additional services
    selectedService?: ADDITIONAL_SERVICES[];
  };
}

const initialState: ServiceAdjustState = {
  initialData: {
    serviceId: undefined,

    // base
    name: '',
    description: '',

    // contact
    phone: '',
    email: '',
    city: '',
    address: '',

    filePreviews: [],

    price: 0,
    customPrice: false,

    selectedService: [],
  },
};

export const serviceAdjustSlice = createSlice({
  name: 'serviceAdjust',
  initialState: initialState,
  reducers: {
    setData: (state, action: PayloadAction<Partial<ServiceAdjustState>>) => {
      state = { ...state, ...action.payload };
      return state;
    },
    resetData: (state) => {
      state = { ...state, ...state.initialData };
      return state;
    },
  },
});

export default serviceAdjustSlice.reducer;
export const { setData, resetData } = serviceAdjustSlice.actions;
