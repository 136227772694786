import { useMemo, useState } from 'react';
import styles from './styles/NotificationsPanel.module.scss';
import NotificationList from './NotificationList';
import { AnimatePresence, motion } from 'framer-motion';
import { useAsync } from 'react-use';
import { useAppDispatch, useAppSelector } from '../../../global/GlobalStore';
import request from '../../../global/helpers/utils';
import {
  NotificationData,
  NotificationItem,
  setData,
} from '../../../global/reducers/NotificationReducer';
import { NotificationType } from '../../../global/helpers/constants';
import { Skeleton } from '@mui/material';
import _ from 'lodash';
import { displayNotification } from '../../../global/reducers/GlobalReducer';

export enum NOTIFICATION_PAGE_SEGMENTS {
  ALL = 0,
  PROFILE_ACTIVITY,
  SERVICE_ACTIVITY,
}

const AnimatedButton: React.FC<{
  onClick: () => void;
  label: string;
  isActive: boolean;
}> = ({ onClick, label, isActive }) => {
  if (isActive) {
    return (
      <motion.button
        animate={{
          border: '2px solid #5d55fa',
          paddingLeft: '23px',
          paddingRight: '23px',
        }}
        exit={{
          border: 'none',
          paddingLeft: '25px',
          paddingRight: '25px',
        }}
        transition={{ duration: 0.2, type: 'just' }}
        onClick={onClick}
        className={`${styles.btn} ${styles.btn__active}`}
        // layoutId='segment'
      >
        {label}
      </motion.button>
    );
  }

  return (
    <button onClick={onClick} className={styles.btn}>
      {label}
    </button>
  );
};

const NotificationsPanel: React.FC = () => {
  const [segment, setSegment] = useState<NOTIFICATION_PAGE_SEGMENTS>(
    NOTIFICATION_PAGE_SEGMENTS.ALL
  );
  const { notifications } = useAppSelector((state) => state.notification);

  const dispatch = useAppDispatch();

  const [isLoading] = useLoadNotifications();
  const [isDisabled, setIsDisabled] = useState(false);

  const NotificationsFilter = useMemo(() => {
    const handleClearAll = async () => {
      setIsDisabled(true);
      await request(
        {
          method: 'POST',
          url: '/v3/private/notifications-clear',
        },
        dispatch,
        false,
        () => {
          setIsDisabled(false);
        }
      );

      dispatch(setData({ notifications: [] }));

      dispatch(
        displayNotification({
          message: 'Pranešimai išvalyti',
          severity: 'success',
        })
      );
      setIsDisabled(false);
    };

    return (
      <>
        <AnimatePresence mode='wait'>
          <div className={styles.btnContainer}>
            <AnimatedButton
              label='Visi'
              isActive={segment === NOTIFICATION_PAGE_SEGMENTS.ALL}
              onClick={() => setSegment(NOTIFICATION_PAGE_SEGMENTS.ALL)}
            />
            <AnimatedButton
              label='Profilio veikla'
              isActive={segment === NOTIFICATION_PAGE_SEGMENTS.PROFILE_ACTIVITY}
              onClick={() =>
                setSegment(NOTIFICATION_PAGE_SEGMENTS.PROFILE_ACTIVITY)
              }
            />
            <AnimatedButton
              label='Skelbimai'
              isActive={segment === NOTIFICATION_PAGE_SEGMENTS.SERVICE_ACTIVITY}
              onClick={() =>
                setSegment(NOTIFICATION_PAGE_SEGMENTS.SERVICE_ACTIVITY)
              }
            />
          </div>
        </AnimatePresence>
        <button
          className={styles.btnEmpty}
          onClick={handleClearAll}
          disabled={isDisabled || !notifications.length}
        >
          Išvalyti viską
        </button>
      </>
    );
  }, [segment, isDisabled, dispatch, notifications]);

  if (isLoading) {
    return (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.filterContainer}>{NotificationsFilter}</div>
          <Skeleton />
          <Skeleton />
          <br />
          <Skeleton />
          <Skeleton />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.filterContainer}>{NotificationsFilter}</div>
        <NotificationList segment={segment} />
      </div>
    </div>
  );
};

export default NotificationsPanel;

const useLoadNotifications = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { initialized } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();

  useAsync(async () => {
    if (!initialized) {
      return;
    }

    setIsLoading(true);
    const notificationData = await request<{
      notifications: {
        id: number;
        userId: number;
        data: NotificationData;
        notificationType: NotificationType;
        createdOn: Date;
      }[];
    }>(
      {
        method: 'GET',
        url: '/v3/private/notifications',
      },
      dispatch,
      false,
      () => {
        setIsLoading(false);
      }
    );

    if (notificationData.notifications) {
      // can be message type notifications
      const messageNotifications: NotificationItem[] = [];
      const notifications: NotificationItem[] = [];

      for (const notification of _.orderBy(
        notificationData.notifications,
        'createdOn',
        'desc'
      )) {
        if (notification.notificationType === NotificationType.UNREAD_MESSAGE) {
          messageNotifications.push({
            ...notification,
            createdOn: notification.createdOn.toString(),
          });
        } else {
          notifications.push({
            ...notification,
            createdOn: notification.createdOn.toString(),
          });
        }
      }

      dispatch(
        setData({
          notifications: notifications,
          messageNotifications: messageNotifications,
        })
      );
    }

    setIsLoading(false);
  }, [initialized]);

  return [isLoading];
};
