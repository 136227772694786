import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProfileState {
  userId?: number; // user id - undefined if not selected user
}

const initialState: ProfileState = {
  userId: undefined,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState: initialState,
  reducers: {
    setData: (state, action: PayloadAction<Partial<ProfileState>>) => {
      state = { ...state, ...action.payload };

      return state;
    },
  },
});

export default profileSlice.reducer;
export const { setData } = profileSlice.actions;
