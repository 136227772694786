import { Fragment, useMemo, useState } from 'react';
import styles from './styles/CommentsPanel.module.scss';

import ratingIcon from '../../../../assets/icons/like-shapes.svg';
import avatarPhIcon from '../../../../assets/icons/avatar-placeholder.svg';
import { useAsync } from 'react-use';
import request from '../../../../global/helpers/utils';
import { useAppDispatch, useAppSelector } from '../../../../global/GlobalStore';
import { Skeleton } from '@mui/material';
import { displayNotification } from '../../../../global/reducers/GlobalReducer';
import _ from 'lodash';

type ProfileComment = {
  id: number;
  userAvatarUrl?: string;
  userName: string;
  rating: number;
  comment: string;
  commentDate: Date;
};

const CommentsPanel: React.FC = () => {
  const user = useAppSelector((state) => state.user); // current user
  const { userId } = useAppSelector((state) => state.profile); // selected user

  const [isLoading, setIsLoading] = useState(true);
  const [comments, setComments] = useState<ProfileComment[]>([]);
  const [commentId, setCommentId] = useState<number | undefined>();

  const [comment, setComment] = useState('');
  const [rating, setRating] = useState(0);
  const [previewRating, setPreviewRating] = useState<number | undefined>(
    undefined
  );
  const [isDisabled, setIsDisabled] = useState(
    userId === undefined || user.id === userId
  );

  const dispatch = useAppDispatch();

  useAsync(async () => {
    setIsLoading(true);

    if (!userId && !user.id) return;

    const response = (await request(
      {
        method: 'GET',
        url: `/v3/private/comment`,
        params: {
          id: userId ?? user.id,
        },
      },
      dispatch
    )) as {
      comments: {
        id: number;
        comment: string;
        userId: number;
        rating: number;
        commentDate: string;
        userAvatarUrl?: string;
        userName: string;
      }[];
    };

    // from comments filter user comment if exists and set it to state

    setComments(
      response.comments.map((c) => ({
        ...c,
        commentDate: new Date(c.commentDate),
      }))
    );

    const userComment = response.comments.find((r) => r.userId === user.id);

    setComment(userComment?.comment ?? '');
    setRating(userComment?.rating ?? 0);
    setCommentId(userComment?.id);

    setIsLoading(false);
  }, [userId, user.id]);

  const handleSubmitComment = async () => {
    if (userId === undefined || userId === user.id) return;
    setIsDisabled(true);

    // send comment to server

    const response = await request(
      {
        method: commentId ? 'PATCH' : 'POST',
        url: `/v3/private/comment`,
        data: {
          userId: userId,
          comment: comment,
          rating: rating,
          commentId: commentId,
        },
      },
      dispatch
    );

    dispatch(
      displayNotification({
        severity: 'success',
        message: `Komentaras ${commentId ? 'atnaujintas' : 'sukurtas'}`,
      })
    );

    if (commentId) {
      // update
      const index = comments.findIndex((c) => c.id === response.id);
      if (index > -1) {
        const updated = _.cloneDeep(comments);
        updated[index].comment = comment;
        setComments([...updated]);
      }
    } else {
      setComments((prev) => [
        ...prev,
        {
          ...response,
          commentDate: response.createdOn,
        },
      ]);
    }

    setCommentId(response?.id);
    setIsDisabled(false);
  };

  const renderRatings = useMemo(() => {
    const getRating = (index: number) => {
      if (rating >= index || (previewRating && previewRating >= index)) {
        return (
          <img
            src={ratingIcon}
            alt='rating-active'
            onClick={() => setRating(index)}
            onMouseEnter={() => setPreviewRating(index)}
            style={{
              cursor: 'pointer',
            }}
          />
        );
      }

      return (
        <img
          src={ratingIcon}
          alt='rating-inactive'
          onClick={() => setRating(index)}
          onMouseEnter={() => setPreviewRating(index)}
          style={{
            filter: 'grayscale(100%)',
            cursor: 'pointer',
          }}
        />
      );
    };

    return (
      <div
        className={styles.ratingContainer}
        onMouseLeave={() => setPreviewRating(undefined)}
      >
        {getRating(1)}
        {getRating(2)}
        {getRating(3)}
        {getRating(4)}
        {getRating(5)}
      </div>
    );
  }, [rating, previewRating]);

  const renderComments = useMemo(() => {
    return (
      <div className={styles.commentedContainer}>
        {isLoading ? (
          <>
            <Skeleton animation='pulse' />
            <Skeleton animation='wave' />
            <Skeleton animation='pulse' />
          </>
        ) : (
          comments.map((comment, index) => (
            <Fragment key={`${index}-fragment`}>
              <CommentCard {...comment} key={`${index}-comment`} />
              {index !== comments.length - 1 && (
                <div className={styles.divider} key={`${index}-divider`} />
              )}
            </Fragment>
          ))
        )}
      </div>
    );
  }, [isLoading, comments]);

  return (
    <div className={styles.commentsContainer}>
      <div className={styles.commentsContainerInner}>
        <h2>Pridėti atsiliepimą</h2>
        <div className={styles.divider} />
        <textarea
          className={styles.commentInput}
          placeholder='Palikite komentarą, būkite malonūs...'
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          disabled={isDisabled || isLoading}
        />
        <div className={styles.btnContainer}>
          {renderRatings}
          <button
            className={styles.commentBtn}
            onClick={handleSubmitComment}
            disabled={isDisabled || isLoading}
          >
            Komentuoti
          </button>
        </div>
        {renderComments}
      </div>
    </div>
  );
};

export default CommentsPanel;

type CommentCardProps = {
  userAvatarUrl?: string;
  userName: string;
  rating: number;
  comment: string;
  commentDate: Date;
};

const CommentCard: React.FC<CommentCardProps> = (props) => {
  const countDays = Math.floor(
    (new Date().getTime() - props.commentDate.getTime()) / (1000 * 60 * 60 * 24)
  );

  return (
    <div className={styles.commentCard}>
      <img src={props.userAvatarUrl ?? avatarPhIcon} alt='avatar' />
      <div className={styles.commentCardInner}>
        <div className={styles.commentCardHeadline}>
          <h3>{props.userName}</h3>
          <div className={styles.commentCardRating}>
            <span>prieš {countDays} d.</span>
            <img src={ratingIcon} alt='rating' />
            {props.rating.toFixed(1)}
          </div>
        </div>
        <p className={styles.comment}>{props.comment}</p>
      </div>
    </div>
  );
};
