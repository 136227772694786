import FAQ from '../../public/landing-page/components/FAQ';

import styles from './styles/FaqPanel.module.scss';

const FaqPanel: React.FC = () => {
  return (
    <div className={styles.panel}>
      <p className={styles.headline}>Dažniausiai užduodami klausimai</p>
      <div className={styles.divider} />
      <FAQ headerOff />
    </div>
  );
};

export default FaqPanel;
