import React, { useState } from 'react';

import styles from '../LoginPage.module.scss';

import lock_icon from '../../../../assets/icons/lock.svg';
import {
  purgeData,
  useAppDispatch,
  useAppSelector,
} from '../../../../global/GlobalStore';
import request from '../../../../global/helpers/utils';
import { useNavigate } from 'react-router-dom';
import CircleLoader from '../../../../components/Loader/CircleLoader';

type ValidateEmailFormProps = {};

const ValidateEmailForm: React.FC<ValidateEmailFormProps> = () => {
  const { authentication } = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();
  const [verifyCode, setVerifyCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleVerifyCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setVerifyCode(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    // Handle form submission logic here
    setIsLoading(true);
    try {
      await request(
        {
          method: 'POST',
          url: '/auth/verify-email',
          data: {
            email: authentication?.email,
            verificationCode: verifyCode,
          },
        },
        dispatch
      );

      // on success

      purgeData();
      navigate('/panel');
    } catch (e) {
      // indicate somewhere is error
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <p className={styles.title}>Įveskite patvirtinimo kodą</p>
      <p className={styles.titleUnderline}>
        išsiuntėme adresu: <span>{authentication?.email}</span>
      </p>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <div className={styles.basicInputBox}>
          <div
            style={{ backgroundImage: `url(${lock_icon})` }}
            className={styles.inputIcon}
          />
          <input
            className={styles.basicInput}
            placeholder='Patvirtinimo kodas'
            type='text'
            id='verifyCode'
            value={verifyCode}
            onChange={handleVerifyCodeChange}
          />
        </div>
        <button type='submit' className={styles.submitBtn}>
          {isLoading ? <CircleLoader width={50} /> : 'Baigti registraciją'}
        </button>
      </form>
    </div>
  );
};

export default ValidateEmailForm;
