import Footer from '../../../public/landing-page/components/Footer';
import ServicesFilter from './ServicesFilter';
import ServicesList from './ServicesList';

import styles from './styles/UserServices.module.scss';

const UserServices: React.FC = () => {
  return (
    <div className={styles.container}>
      <ServicesFilter />
      <ServicesList />
      <Footer />
    </div>
  );
};

export default UserServices;
