import logout from '../../assets/icons/logout.svg';
import settings from '../../assets/icons/setting.svg';
import profile from '../../assets/icons/profile.svg';

import {
  emoji_1,
  emoji_2,
  emoji_3,
  emoji_4,
  emoji_5,
  emoji_6,
  emoji_7,
  emoji_8,
  emoji_9,
  emoji_10,
  emoji_11,
  emoji_12,
  emoji_13,
  emoji_14,
  emoji_15,
  emoji_16,
  emoji_17,
  emoji_18,
  emoji_19,
  emoji_20,
  emoji_21,
  emoji_22,
  emoji_23,
  emoji_24,
} from '../../assets/emoji/emojiExports'; // Import from the index file

export enum MainPanelSegments {
  USER_SERVICES = 1,
  FAQ,
  PRIVACY_POLICY,
  CONTACTS,
  PROFILE_SETTINGS,
  MESSAGES_CENTER,
  SERVICES_IN_MEMORY,
  NOTIFICATIONS,

  PROFILE,

  ADJUST_SERVICE,

  LOGOUT, // NOT A SEGMENT, JUST A TRIGGER
}

export type MenuOption = {
  icon?: JSX.Element;
  label: string;
  underline?: string;
  value: MainPanelSegments;
  isDisabled?: boolean;
  onClick?: () => void;
};

export const menuOptions: MenuOption[] = [
  {
    label: 'Skelbimai',
    underline: 'Surask savo tinkamiausia tatto meistrą',
    value: MainPanelSegments.USER_SERVICES,
  },
  {
    label: 'D.U.K',
    underline: 'Dažniausiai užduodami klausimai',
    value: MainPanelSegments.FAQ,
  },
  // {
  //   label: 'Privatumo politika',
  //   value: MainPanelSegments.PRIVACY_POLICY,
  // },
  // {
  //   label: 'Kontaktai',
  //   value: MainPanelSegments.CONTACTS,
  // },
];

export const menuOptionsDropdown: MenuOption[] = [
  {
    label: 'Mano profilis',
    underline: 'Peržiūrėk savo paskyrą',
    value: MainPanelSegments.PROFILE,
    icon: <img src={profile} alt='profile' />,
  },
  {
    label: 'Profilio nustatymai',
    underline: 'Keisk savo paskyros nustatymus',
    value: MainPanelSegments.PROFILE_SETTINGS,
    icon: <img src={settings} alt='settings' />,
  },
  {
    label: 'Atsijungti',
    value: MainPanelSegments.LOGOUT,
    icon: <img src={logout} alt='logout' />,
  },
];

export const extraOptions: MenuOption[] = [
  {
    label: 'Pranešimų centras',
    underline: 'Sekite naujienas ir pranešimus nuo kitų vartotojų',
    value: MainPanelSegments.MESSAGES_CENTER,
  },
  {
    label: 'Įsiminti skelbimai',
    underline: 'Įsiminti skelbimai',
    value: MainPanelSegments.SERVICES_IN_MEMORY,
  },
  {
    label: 'Įkelti skelbimą',
    underline: 'Parodyk klientam ką sugebi !',
    value: MainPanelSegments.ADJUST_SERVICE,
  },
  {
    label: 'Pranešimai',
    // underline: 'Pranešimai',
    value: MainPanelSegments.NOTIFICATIONS,
  },
];

// export to common
export enum NotificationType {
  // profile notifications
  COMMENT_PROFILE = 1,

  LIKED_ADVERTISEMENT = 2,

  // message notifications
  UNREAD_MESSAGE = 3,

  INVITED_TO_CHAT = 4,
}

export const emojiList = [
  emoji_1,
  emoji_2,
  emoji_3,
  emoji_4,
  emoji_5,
  emoji_6,
  emoji_7,
  emoji_8,
  emoji_9,
  emoji_10,
  emoji_11,
  emoji_12,
  emoji_13,
  emoji_14,
  emoji_15,
  emoji_16,
  emoji_17,
  emoji_18,
  emoji_19,
  emoji_20,
  emoji_21,
  emoji_22,
  emoji_23,
  emoji_24,
];
