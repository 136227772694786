import { useEffect, useRef, useState } from 'react';
import styles from './BurgerButton.module.scss';

type BurgerButtonProps = {
  onClick: () => void;
  burgerStyleType?: 'cross-middle' | 'cross-top' | 'cross-merge';
  isOpen?: boolean;
};

const BurgerButton: React.FC<BurgerButtonProps> = ({
  onClick,
  burgerStyleType,
  isOpen,
}) => {
  const elementRef = useRef<HTMLDivElement>(null);

  const [isActive, setIsActive] = useState(
    isOpen !== undefined ? isOpen : false
  );

  useEffect(() => {
    const active = isOpen !== undefined ? isOpen : isActive;
    if (isOpen !== undefined) {
      setIsActive(isOpen);
    }

    if (active) {
      elementRef.current?.classList.add(styles.open);
    } else {
      elementRef.current?.classList.remove(styles.open);
    }
  }, [isActive, isOpen]);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    setIsActive(!isActive);
  };

  if (burgerStyleType === 'cross-middle') {
    return (
      <>
        <div id={styles.nav_icon1} onClick={handleClick} ref={elementRef}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </>
    );
  }

  if (burgerStyleType === 'cross-top') {
    return (
      <>
        <div id={styles.nav_icon2} ref={elementRef} onClick={handleClick}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </>
    );
  }

  if (burgerStyleType === 'cross-merge') {
    <div id={styles.nav_icon3} ref={elementRef} onClick={handleClick}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>;
  }

  return (
    <>
      <div id={styles.nav_icon4} ref={elementRef} onClick={handleClick}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </>
  );
};

export default BurgerButton;
