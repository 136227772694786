import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { globalStore, persistor } from './global/GlobalStore';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/public/landing-page/LandingPage';
import NotFoundPage from './pages/public/404-page/NotFoundPage';

import './global/styles/_global.scss';
import LoginPage from './pages/public/login-page/LoginPage';
import MainPanel from './pages/admin/main-panel/MainPanel';
import { CssBaseline } from '@mui/material';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <>
      <CssBaseline />
      <Provider store={globalStore}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Routes>
              <Route index element={<LandingPage />} />
              <Route path='login' element={<LoginPage />} />
              <Route path='panel' element={<MainPanel />} />
              <Route path='*' element={<NotFoundPage />} />
            </Routes>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </>
  </React.StrictMode>
);
