import { useNavigate } from 'react-router-dom';
import styles from './NotFoundPage.module.scss';

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.headContainer}>
      <div className={styles.container}>
        <div className={styles.noise}></div>
        <div className={styles.overlay}></div>
        <div className={styles.terminal}>
          <h1>
            Error <span className={styles.errorcode}>404 - NOT FOUND</span>
          </h1>
          <p className={styles.output}>
            The page you are looking for might have been removed, had its name
            changed or is temporarily unavailable.
          </p>
          <p className={styles.output}>
            Please try to{' '}
            <a
              href={window.location.href}
              style={{ cursor: 'pointer' }}
              onClick={() => navigate(-1)}
            >
              go back
            </a>{' '}
            or{' '}
            <a
              href={window.location.href}
              style={{ cursor: 'pointer' }}
              onClick={() => navigate('/')}
            >
              return to the homepage
            </a>
            .
          </p>
          <p className={styles.output}>Good luck.</p>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
