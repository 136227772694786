import { useRef, useState } from 'react';
import { TextAreaInput } from '../../../../components/Input/Input';
import styles from './styles/EditProfile.module.scss';

import avatarPlaceholder from '../../../../assets/icons/avatar-placeholder.svg';
import { useAsync } from 'react-use';
import { Skeleton } from '@mui/material';
import request from '../../../../global/helpers/utils';
import { useAppDispatch } from '../../../../global/GlobalStore';
import { FilePreview } from '../../../../global/reducers/ServiceAdjustReducer';
import { displayNotification } from '../../../../global/reducers/GlobalReducer';

const EditProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [websiteLink, setWebsiteLink] = useState('');
  const [avatar, setAvatar] = useState<string | null>(null);
  const [avatarFile, setAvatarFile] = useState<File | null>(null);

  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');

  const [description, setDescription] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  useAsync(async () => {
    setIsLoading(true);

    const response = await request(
      {
        method: 'GET',
        url: '/v3/private/user/edit/base-info',
      },
      dispatch
    );

    setUsername(response.username || '');
    setName(response.name || '');
    setEmail(response.email || '');
    setWebsiteLink(response.websiteLink || '');
    setAvatar(response.avatar || undefined);
    setCity(response.city || '');
    setAddress(response.address || '');
    setDescription(response.description || '');

    setIsLoading(false);
  }, []);

  const handleChangeAvatar = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const preview: FilePreview = {
        url: URL.createObjectURL(file),
        type: file.type,
        object: file,
      };

      setAvatar(preview.url);
      setAvatarFile(file);
    }
  };

  const handleSubmit = async () => {
    setIsDisabled(true);

    const data = new FormData();

    data.append('name', name);
    data.append('username', username);
    data.append('email', email);
    data.append('websiteLink', websiteLink);

    data.append('city', city);
    data.append('address', address);

    data.append('description', description);

    if (avatarFile) {
      data.append('avatarImageFile', avatarFile);
    }

    await request(
      {
        method: 'POST',
        url: '/v3/private/user/edit/base-info',
        data,
      },
      dispatch
    );

    dispatch(
      displayNotification({
        message: 'Informacija atnaujinta!',
        severity: 'success',
      })
    );

    setIsDisabled(false);
  };

  if (isLoading) {
    return (
      <div className={styles.container}>
        <p className={styles.headline}>Asmeninė informacija</p>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <p className={styles.headline}>Asmeninė informacija</p>
      <div className={styles.spacer} />
      <div className={styles.profileContainer}>
        <p className={styles.hText}>Profilio nuotrauka</p>
        <div className={styles.profileImageContainer}>
          <div
            className={styles.avatarPic}
            style={{
              background: `url(${
                avatar ?? avatarPlaceholder
              }) lightgray 50% / cover no-repeat`,
            }}
          />
          <input
            ref={fileInputRef}
            type='file'
            accept='image/*'
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <button className={styles.btn} onClick={handleChangeAvatar}>
            Pakeisti avatarą
          </button>
          <p>
            Atnaujinkite savo avatarą spustelėję toliau esantį paveikslėlį.
            Rekomenduojamas 288x288 px dydis tik PNG arba JPG formatu.
          </p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputA}>
            <p>Slapyvardis</p>
            <input
              onChange={(e) => setUsername(e.currentTarget.value)}
              value={username}
            />
          </div>
          <div className={styles.inputA}>
            <p>Vardas</p>
            <input
              onChange={(e) => setName(e.currentTarget.value)}
              value={name}
            />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputA}>
            <p>Elektroninis paštas</p>
            <input
              onChange={(e) => setEmail(e.currentTarget.value)}
              value={email}
            />
          </div>
          <div className={styles.inputA}>
            <p>Asmeninis tinklaraštis</p>
            <input
              onChange={(e) => setWebsiteLink(e.currentTarget.value)}
              value={websiteLink}
            />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputA}>
            <p>Miestas</p>
            <input
              onChange={(e) => setCity(e.currentTarget.value)}
              value={city}
            />
          </div>
          <div className={styles.inputA}>
            <p>Adresas</p>
            <input
              onChange={(e) => setAddress(e.currentTarget.value)}
              value={address}
            />
          </div>
        </div>
        <div className={styles.inputContainerTextArea}>
          <TextAreaInput
            label='Apie savę'
            onChange={(e) => setDescription(e.currentTarget.value)}
            value={description}
          />
        </div>
      </div>
      <button
        className={styles.submitBtn}
        onClick={handleSubmit}
        disabled={isDisabled}
      >
        Išsaugoti
      </button>
    </div>
  );
};

export default EditProfile;
