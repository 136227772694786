import styles from './Toggle.module.scss';

type ToggleInputProps = {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
};

const ToggleInput: React.FC<ToggleInputProps> = ({
  checked = false,
  onChange,
}) => {
  return (
    <>
      <label className={styles.switch}>
        <input
          checked={checked}
          type='checkbox'
          onChange={() => onChange && onChange(!checked)}
        />
        <span className={`${styles.slider} ${styles.round}`}></span>
      </label>
    </>
  );
};

export default ToggleInput;
