import { useRef, useState } from 'react';
import styles from './styles/FAQ.module.scss';
import _ from 'lodash';
import { AnimatePresence, motion } from 'framer-motion';

type FAQprops = {
  headerOff?: boolean;
};

const FAQ: React.FC<FAQprops> = ({ headerOff = false }) => {
  return (
    <div className={styles.container}>
      {!headerOff && (
        <p className={styles.title}>Ką turi žinoti kiekvienas mūsų klientas.</p>
      )}
      <Accordion />
    </div>
  );
};

export default FAQ;

type AccordionItemProps = {
  index: number;
  label: string;
  text: string;
  isOpen: boolean;
  onClick: () => void;
};

const AccordionItem: React.FC<AccordionItemProps> = ({
  index,
  label,
  text,
  isOpen,
  onClick,
}) => {
  const contentHeight = useRef<HTMLDivElement>(null);

  return (
    <>
      <motion.div
        className={styles.wrapper}
        initial={{ opacity: 0, x: -150, scale: 0.1 }}
        whileInView={{ opacity: 1, x: 0, scale: 1 }}
        transition={{ duration: 0.5, delay: 0.5 * index }}
        viewport={{ once: true, amount: 0.1 }}
      >
        <button className={`${styles.accordionBtn}`} onClick={onClick}>
          <p>{label}</p>
          <div
            className={`${styles.accordionIcon} ${
              isOpen ? styles.accordionIcon_active : null
            }`}
          >
            <span />
            <span />
          </div>
        </button>
        <div
          ref={contentHeight}
          className={styles.accordionItemTextContainer}
          style={
            isOpen
              ? {
                  height: contentHeight?.current?.scrollHeight,
                }
              : { height: '0px' }
          }
        >
          <p
            className={styles.accordionItemText}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
      </motion.div>
    </>
  );
};

const Accordion: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleItemClick = (index: number) => {
    setActiveIndex((prevIndex: number | null) =>
      prevIndex === index ? null : index
    );
  };

  return (
    <AnimatePresence>
      <div className={styles.accordionContainer}>
        {_.map(mockData, (item, index) => (
          <AccordionItem
            index={index}
            key={index}
            label={item.label}
            text={item.text}
            isOpen={activeIndex === index}
            onClick={() => handleItemClick(index)}
          />
        ))}
      </div>
    </AnimatePresence>
  );
};

const mockData = [
  {
    label: 'Kokia yra skelbimo kaina?',
    text: 'Kiekvienas meistras Tatto platformoje nustato savo skelbimo kainą individualiai. Kainos gali skirtis priklausomai nuo paslaugų, dizaino sudėtingumo, meistro patirties ir kitų veiksnių. Norėdami sužinoti tikslią kainą, peržiūrėkite konkretaus meistro profilį arba susisiekite su juo tiesiogiai per platformą.',
  },
  {
    label: 'Kaip galėčiau ištrinti/redaguoti savo skelbimą?',
    text: `
    Norėdami ištrinti arba redaguoti savo skelbimą Tatto sistemoje, atlikite šiuos veiksmus:
    <br/>
    Prisijunkite prie savo paskyros: Įveskite savo prisijungimo duomenis ir eikite į savo asmeninį profilį.
    <br/>
    Raskite savo skelbimą: Pasirinkite „Mano skelbimai“ arba „Mano galerija“ skiltį, kurioje bus rodomi visi jūsų sukurti skelbimai.
    <br/>
    Redaguoti skelbimą: Paspauskite ant skelbimo, kurį norite redaguoti, ir pasirinkite „Redaguoti“. Galėsite pakeisti tekstą, nuotraukas, kainą arba kitas detales. Baigę redaguoti, nepamirškite išsaugoti pakeitimų.
    <br/>
    Ištrinti skelbimą: Jei norite visiškai pašalinti skelbimą, pasirinkite „Ištrinti“ ir patvirtinkite veiksmą. Skelbimas bus pašalintas iš sistemos ir nebus matomas kitiems vartotojams.
<br/><br/>
Jei iškilo problemų arba turite klausimų, kreipkitės į mūsų klientų aptarnavimo komandą, kuri visada pasirengusi jums padėti.
    `,
  },
  {
    label: 'Kas yra asmeninis Tatto.lt puslapis ir kaip juo naudotis?',
    text: 'Asmeninis Tatto puslapis – tai unikali jūsų tatuiruočių kolekcijos platforma, kurioje galite tvarkyti ir dalintis savo tatuiruočių dizainais bei idėjomis. Šis puslapis leidžia ne tik kaupti jūsų esamas tatuiruotes, bet ir planuoti būsimas, susisiekti su tatuiruočių meistrais bei gauti atsiliepimus iš kitų bendruomenės narių.',
  },
];
