import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../global/GlobalStore';
// import { handleScrollTo } from '../../../../global/helpers/utils';
import { setAuthenticationData } from '../../../../global/reducers/GlobalReducer';
import { FormSegment } from '../../login-page/LoginPage';

import styles from './styles/HeroBanner.module.scss';

import background from '../../../../assets/public/herobanner/background_herobanner.webp';
import { AnimatePresence, motion } from 'framer-motion';

const HeroBanner: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleNavigate = () => {
    dispatch(setAuthenticationData({ segment: FormSegment.LOGIN }));
    navigate('/login');
  };

  return (
    <AnimatePresence>
      <div
        style={{
          background: `url(${background}) lightgray 50% / cover no-repeat`,
        }}
        className={styles.container}
      >
        {/* <div className={styles.background} /> */}
        <p className={styles.title}>
          Pradėkite dirbti su tatuiruočių skelbimų portalu
        </p>
        <p className={styles.underline}>
          Išplėskite savo klientų ratą ir padidinkite pajamas teikdami
          plačiausių meistrų skelbimų portale Lietuvoje !
        </p>
        <div className={styles.buttonBox}>
          <motion.button
            className={styles.loginBtn}
            onClick={handleNavigate}
            initial={{
              scale: 0.2,
            }}
            whileInView={{
              scale: 1,
            }}
            transition={{
              duration: 0.3,
            }}
            viewport={{
              once: true,
              amount: 0.1,
            }}
          >
            Prisijungti
          </motion.button>
          {/* <button
          className={styles.priceBtn}
          onClick={() => handleScrollTo('services_anchor')}
        >
          Kainoraštis
        </button> */}
        </div>
      </div>
    </AnimatePresence>
  );
};

export default HeroBanner;
