import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { USER_PERMISSIONS } from '@web-app/common';

export interface UserState {
  id: number | null;
  email: string | null;
  permissions: USER_PERMISSIONS[];
  avatarFileName?: string;
  name: string;
  rating: number;
  ratingCount: number;
}

const initialState: UserState = {
  id: null,
  email: null,
  permissions: [],
  name: 'Nezinomas vartotojas',
  rating: 0,
  ratingCount: 0,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<Partial<UserState>>) => {
      return { ...state, ...action.payload };
    },
    resetUserData: () => {
      return initialState;
    },
  },
});

export default userSlice.reducer;
export const { setUserData, resetUserData } = userSlice.actions;
