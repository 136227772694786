import styles from '../LoginPage.module.scss';
import key_icon from '../../../../assets/icons/key.svg';
import { useState } from 'react';
import CircleLoader from '../../../../components/Loader/CircleLoader';
import { useAppDispatch } from '../../../../global/GlobalStore';
import {
  displayNotification,
  setAuthenticationData,
} from '../../../../global/reducers/GlobalReducer';
import request from '../../../../global/helpers/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormSegment } from '../LoginPage';

type ResetForgottenPasswordFormProps = {
  token: string;
};

const ResetForgottenPasswordForm: React.FC<ResetForgottenPasswordFormProps> = ({
  token,
}) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');

  const dispatch = useAppDispatch();

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);

  const handlePasswordRepeatChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPasswordRepeat(e.target.value);

  const handleSubmit = async () => {
    if (isLoading) return;

    setIsLoading(true);

    if (password !== passwordRepeat) {
      dispatch(
        displayNotification({
          message: 'Slaptažodžiai nesutampa',
          severity: 'error',
        })
      );
      setIsLoading(false);
      return;
    }

    try {
      await request(
        {
          method: 'POST',
          url: '/auth/reset-forgotten-password',
          data: {
            token: token,
            password: password,
          },
        },
        dispatch,
        false,
        () => setIsLoading(false)
      );

      dispatch(
        displayNotification({
          message: 'Slaptažodis sėkmingai atnaujintas',
          severity: 'success',
        })
      );

      navigate({
        search: '',
      });

      dispatch(setAuthenticationData({ segment: FormSegment.LOGIN }));
    } catch (e) {
      // indicate somewhere is error
    } finally {
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  return (
    <div>
      <p className={styles.title}>Atnaujinti slaptažodį</p>

      <div className={styles.formContainer}>
        <div className={styles.basicInputBox}>
          <div
            style={{ backgroundImage: `url(${key_icon})` }}
            className={styles.inputIcon}
          />
          <input
            className={styles.basicInput}
            placeholder='Slaptažodis'
            type='password'
            id='password'
            value={password}
            onChange={handlePasswordChange}
          />
        </div>
        <div className={styles.basicInputBox}>
          <div
            style={{ backgroundImage: `url(${key_icon})` }}
            className={styles.inputIcon}
          />
          <input
            className={styles.basicInput}
            placeholder='Pakartokite slaptažodį'
            type='password'
            id='password-repeat'
            value={passwordRepeat}
            onChange={handlePasswordRepeatChange}
          />
        </div>
        <button
          type='submit'
          onClick={handleSubmit}
          className={styles.submitBtn}
          disabled={isLoading}
        >
          {isLoading ? <CircleLoader width={50} /> : <>Atnaujinti slaptažodį</>}
        </button>
      </div>
    </div>
  );
};

export default ResetForgottenPasswordForm;
