import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ADDITIONAL_SERVICES } from '@web-app/common';

interface ServiceModalState {
  isOpen: boolean;
  isPreviewMode?: boolean;
  isCommentsOpen: boolean;
  isLoading: boolean;

  selectedServiceId?: number;
  likeCount: number;

  //service data
  title?: string;
  price?: number;
  city?: string;
  address?: string;
  phone?: string;
  services?: ADDITIONAL_SERVICES[];
  description?: string;
  serviceImageUrl?: string;

  comments?: {
    authorImageUrl?: string;
    authorName: string;
    updatedOn: string;
    rating: number; // author rating
    comment: string;
    ownerId: number;
    id?: number;
  }[];

  // service author data
  authorImageUrl?: string;
  authorName?: string;
  authorRating?: number;
  authorReviewCount?: number;
  authorId?: number;

  // service files
  files?: {
    fileName?: string; // with url
    originalFileName?: string;
    mimeType?: string;
  }[];
  openLightBox?: boolean;
}

const initialState: ServiceModalState = {
  isOpen: false,
  isCommentsOpen: false,
  likeCount: 0,
  isLoading: true,

  //service data
  authorRating: 0,
  authorReviewCount: 0,

  comments: [],
};

export const serviceModalSlice = createSlice({
  name: 'serviceModal',
  initialState: initialState,
  reducers: {
    setData: (state, action: PayloadAction<Partial<ServiceModalState>>) => {
      return { ...state, ...action.payload };
    },
    resetData: (state) => {
      return { ...state, ...initialState };
    },
    closeModal: (state) => {
      return {
        ...state,
        isOpen: false,
        // selectedServiceId: undefined,
        isPreviewMode: false,
        isCommentsOpen: false,
      };
    },
    openModal: (
      state,
      action: PayloadAction<{ selectedServiceId: number }>
    ) => {
      return {
        ...state,
        isOpen: true,
        isLoading: state.selectedServiceId !== action.payload.selectedServiceId,
        selectedServiceId: action.payload.selectedServiceId,
      };
    },
    previewModal: (
      state,
      action: PayloadAction<
        Partial<
          Omit<
            ServiceModalState,
            'isOpen' | 'isPreviewMode' | 'selectedServiceId'
          >
        >
      >
    ) => {
      return {
        ...state,
        ...action.payload,
        isPreviewMode: true,
        isOpen: true,
        isLoading: false,
      };
    },
    handleLightBox: (
      state,
      action: PayloadAction<{
        files?: ServiceModalState['files'];
        isOpen: boolean;
      }>
    ) => {
      return {
        ...state,
        files: action.payload.files || state.files,
        openLightBox: action.payload.isOpen,
      };
    },
  },
});

export default serviceModalSlice.reducer;
export const {
  setData,
  resetData,
  closeModal,
  openModal,
  previewModal,
  handleLightBox,
} = serviceModalSlice.actions;
