import { useState } from 'react';
import { Input } from '../../../../components/Input/Input';
import styles from './styles/EditPassword.module.scss';
import { useAppDispatch } from '../../../../global/GlobalStore';
import { displayNotification } from '../../../../global/reducers/GlobalReducer';
import request from '../../../../global/helpers/utils';

const EditPassword: React.FC = () => {
  const dispatch = useAppDispatch();

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [isDisabled, setIsDisabled] = useState(false);

  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      dispatch(
        displayNotification({
          message: 'Nesutampa slaptažodžiai',
          severity: 'warning',
        })
      );

      return;
    }

    if (newPassword.length < 6) {
      dispatch(
        displayNotification({
          message: 'Per trumpas naujas slaptažodis',
          severity: 'warning',
        })
      );

      return;
    }

    setIsDisabled(true);

    await request(
      {
        method: 'POST',
        url: '/v3/private/user/edit/pass-info',
        data: {
          currentPassword,
          newPassword,
        },
      },
      dispatch,
      false,
      () => setIsDisabled(false)
    );

    dispatch(
      displayNotification({
        message: 'Informacija atnaujinta!',
        severity: 'success',
      })
    );

    setIsDisabled(false);
  };

  const submitDisabled = isDisabled || !currentPassword.length;

  return (
    <div className={styles.container}>
      <p className={styles.headline}>Atnaujinti slaptažodį</p>
      <div className={styles.spacer} />
      <p className={styles.underline}>
        Nepamirškite slaptažodžio nelaikyti el. pašte ar debesyje ir su niekuo
        juo nesidalykite.
      </p>
      <div className={styles.profileContainer}>
        <Input
          label='Dabartinis slaptažodis'
          type='password'
          value={currentPassword}
          onChange={(value) => setCurrentPassword(value)}
          autocomplete='current-password'
        />
        <Input
          label='Naujas slaptažodis'
          value={newPassword}
          type='password'
          underline={'Mažiausiai 6 simboliai'}
          onChange={(value) => setNewPassword(value)}
          autocomplete='new-password'
        />
        <Input
          label='Patvirtinti naują slaptažodį'
          value={confirmPassword}
          type='password'
          underline={'Mažiausiai 6 simboliai'}
          onChange={(value) => setConfirmPassword(value)}
        />
      </div>
      <button
        className={styles.submitBtn}
        disabled={submitDisabled}
        onClick={handleSubmit}
      >
        Atnaujinti slaptažodį
      </button>
    </div>
  );
};

export default EditPassword;
