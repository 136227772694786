import React, { useEffect } from 'react';
import { io, Socket } from 'socket.io-client';
import { useAppDispatch, useAppSelector } from '../GlobalStore';
import { displayNotification } from '../reducers/GlobalReducer';
import { NotificationType } from './constants';
import { setUserData } from '../reducers/UserReducer';
import { addNotification } from '../reducers/NotificationReducer';
import {
  addMessage,
  ChatRoom,
  persistChatRoom,
} from '../reducers/MessageCenterReducer';

// prod: '/'
// local: 'http://localhost:4000'

export enum SOCKET_ACTIONS {
  JOIN_SERVER_ROOM = 'join-server',

  // notifications
  NOTIFY_USER = 'notification',
  NOTIFY_USER_INFO_CHANGE = 'user-info-change',

  USER_SEEN_MESSAGE = 'user-seen-message',
  USER_SENDS_MESSAGE = 'user-sends-message',
  SOMEONE_SENT_MESSAGE = 'someone-sent-message', // to listen on chat room
}

export const socket = io(process.env.REACT_APP_SOCKET_API ?? '/', {
  withCredentials: true,
  autoConnect: false,
  reconnection: true,
  secure: true,
  transports: ['websocket', 'polling'],
});

export const SocketContext = React.createContext<Socket<any, any> | undefined>(
  undefined
);

export const useInitiateSocket = () => {
  const { id } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    socket.connect();
    socket.on('connect', () => {
      socket.emit(SOCKET_ACTIONS.JOIN_SERVER_ROOM);

      dispatch(
        displayNotification({
          message: 'Sėkmingai prisijungta prie serverio',
          severity: 'info',
        })
      );
    });

    return () => {
      socket.off('connect');
    };
  }, [id, dispatch]);
};

export const useNotificationListener = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    socket.on(
      SOCKET_ACTIONS.NOTIFY_USER,
      (data: {
        userName: string;
        notificationType: NotificationType;
        metaData?: {
          chatRoom?: ChatRoom;
        };
      }) => {
        let message = 'Gautas pranešimas';

        if (data.notificationType === NotificationType.COMMENT_PROFILE) {
          message = `${data.userName} paliko komentarą jūsų profilyje`;

          dispatch(
            addNotification({
              id: -1, // dummy id
              userId: -1, // dummy id
              notificationType: NotificationType.COMMENT_PROFILE,
              data: { userName: data.userName },
              createdOn: new Date().toISOString(),
            })
          );
        }
        if (data.notificationType === NotificationType.LIKED_ADVERTISEMENT) {
          message = `${data.userName} patiko jūsų skelbimas`;
          dispatch(
            addNotification({
              id: -1, // dummy id
              userId: -1, // dummy id
              notificationType: NotificationType.LIKED_ADVERTISEMENT,
              data: { userName: data.userName },
              createdOn: new Date().toISOString(),
            })
          );
        }
        if (data.notificationType === NotificationType.INVITED_TO_CHAT) {
          message = `${data.userName} pakvietė jus į pokalbį`;

          // add new chatRoom
          if (data.metaData?.chatRoom) {
            dispatch(persistChatRoom(data.metaData.chatRoom));
          }
        }

        dispatch(
          displayNotification({
            message: message,
            severity: 'info',
          })
        );
      }
    );

    socket.on(
      SOCKET_ACTIONS.NOTIFY_USER_INFO_CHANGE,
      (data: { name?: string; avatarFileName?: string }) => {
        dispatch(setUserData({ ...data }));
      }
    );

    socket.on(
      SOCKET_ACTIONS.SOMEONE_SENT_MESSAGE,
      (data: {
        id: number;
        chatRoomId: number;
        userId: number;
        message: string;
        metaData: {
          replyTo?: {
            messageId: number;
          };
          attachedFilenames?: string[];
          attachedFiles?: {
            fileName: string;
            originalFileName: string;
            mimeType: string;
          }[];
        };
      }) => {
        dispatch(
          addMessage({
            chatRoomId: data.chatRoomId,

            message: {
              id: data.id,
              userId: data.userId,
              chatRoomId: data.chatRoomId,
              message: data.message,
              createdOn: new Date().toISOString(),
              metaData: data.metaData,
            },
          })
        );
      }
    );

    return () => {
      socket.off(SOCKET_ACTIONS.NOTIFY_USER);
      socket.off(SOCKET_ACTIONS.NOTIFY_USER_INFO_CHANGE);
      socket.off(SOCKET_ACTIONS.SOMEONE_SENT_MESSAGE);
    };
  }, [dispatch]);
};
