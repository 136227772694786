import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../global/GlobalStore';

import fileUploadIcon from '../../../../assets/icons/folder-add.svg';
import emojiIcon from '../../../../assets/icons/emoji-happy.svg';

import styles from '../styles/MessageInput.module.scss';
import _ from 'lodash';
import { Menu, Tooltip } from '@mui/material';

import { emojiList } from '../../../../global/helpers/constants';
import { FilePreview } from '../../../../global/reducers/ServiceAdjustReducer';
import {
  SOCKET_ACTIONS,
  SocketContext,
} from '../../../../global/helpers/socket_context';
import request from '../../../../global/helpers/utils';

const MessageInput: React.FC = () => {
  const dispatch = useAppDispatch();
  const { activeChatRoom } = useAppSelector((state) => state.messageCenter);

  const [message, setMessage] = useState('');
  const [filesToUpload, setFilesToUpload] = useState<FilePreview[]>([]);
  const editableDivRef = useRef<HTMLDivElement>(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const socket = useContext(SocketContext);

  const handleSend = useCallback(async () => {
    if (_.trim(message).length < 1 && filesToUpload.length < 1) {
      // no sending empty strings without files
      return;
    }

    setIsDisabled(true);

    // process files, emojis and send message
    // send files by request
    const attachmentFilenames: string[] = [];

    if (filesToUpload.length > 0) {
      // send files
      const formData = new FormData();
      filesToUpload.forEach((file) => {
        formData.append('files', file.object);
      });

      const response = await request<
        {
          fileName: string;
          originalFileName: string;
          fileExtensionMimeType: string;
          fileUrl: string;
        }[]
      >(
        {
          method: 'POST',
          url: `/v3/private/file/upload`,
          data: formData,
        },
        dispatch,
        false,
        () => setIsDisabled(false)
      );

      if (response) {
        for (const file of response) {
          attachmentFilenames.push(file.fileName);
        }
      }
    }
    // by socket
    socket?.emit(SOCKET_ACTIONS.USER_SENDS_MESSAGE, {
      chatRoomId: activeChatRoom?.id,
      message: message,

      // metaData
      replyToMessageId: null, // if message is reply to another message
      attachmentFilenames: attachmentFilenames ?? [],
    });

    // reset states
    setMessage('');
    if (editableDivRef.current) editableDivRef.current.innerHTML = '';
    setFilesToUpload([]);

    setIsDisabled(false);

    // reset input height
    const textarea = document.getElementById('messageInput');
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = '58px';
    }
  }, [message, filesToUpload, activeChatRoom]);

  const handleEmojiSelect = (emojiCode: string) => {
    if (editableDivRef.current) {
      editableDivRef.current.innerHTML += `<img src="${emojiCode}" />`;
      setMessage(editableDivRef.current.innerHTML);
    }
  };

  const handleTextAreaInputChange = (e: React.ChangeEvent<HTMLDivElement>) => {
    if (isDisabled) {
      return;
    }

    setMessage(e.target.innerHTML);
    if (editableDivRef.current) {
      setMessage(editableDivRef.current.innerHTML);
    }
  };

  const handleFileSelect = (files: FilePreview[]) => {
    setFilesToUpload(files);
  };

  const handleKeyDownEvents = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  // controls textarea height
  var textarea = document.getElementById('messageInput');

  if (textarea) {
    textarea.oninput = function () {
      if (!textarea) {
        return;
      }

      textarea.style.height = 'auto';

      let height = Math.max(textarea.scrollHeight, 58);

      if (height > 174) {
        height = 174;
      } else if (height < 58) {
        height = 58;
      }

      textarea.style.height = height + 'px';
    };
  }
  // ----------

  return (
    <div className={styles.inputContainer}>
      <FileUploader
        onFilesSelect={handleFileSelect}
        selectedFiles={filesToUpload}
      />
      <EmojiPicker
        onEmojiSelect={handleEmojiSelect}
        editableRef={editableDivRef}
      />
      <div className={styles.textAreaInput}>
        <div
          onKeyDown={handleKeyDownEvents}
          ref={editableDivRef}
          contentEditable
          id='messageInput'
          className={styles.textArea}
          onInput={handleTextAreaInputChange}
        />
        <button onClick={handleSend} disabled={isDisabled}>
          Siųsti
        </button>
      </div>
    </div>
  );
};

export default MessageInput;

type EmojiPickerProps = {
  onEmojiSelect: (emoji: string) => void;
  editableRef: React.RefObject<HTMLDivElement>;
};

const EmojiPicker: React.FC<EmojiPickerProps> = ({
  onEmojiSelect,
  editableRef,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (editableRef.current) {
      editableRef.current.onblur = (e) => e.preventDefault();
    }

    setAnchorEl(e.currentTarget);
  };

  const emoji = useMemo(() => {
    return emojiList.map((emoji, index) => (
      <div
        onClick={() => {
          onEmojiSelect(emoji);
          setAnchorEl(null);
        }}
        key={index}
        className={styles.emojiItem}
      >
        <img src={emoji} alt={`emoji-${index}`} />
      </div>
    ));
  }, []);

  return (
    <>
      <div className={styles.inputIcon} onClick={onClick}>
        <img src={emojiIcon} alt='emojiSelector' />
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        classes={{
          paper: styles.dropdownMenu,
        }}
        sx={{ maxWidth: '250px' }}
      >
        <div className={styles.emojiContainer}>{emoji}</div>
      </Menu>
    </>
  );
};

type FileUploaderProps = {
  onFilesSelect: (files: FilePreview[]) => void;
  selectedFiles?: FilePreview[];
};

const FileUploader: React.FC<FileUploaderProps> = ({
  onFilesSelect,
  selectedFiles,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (!files) {
      return;
    }

    // process files

    const previews: FilePreview[] = Array.from(files).map((file) => ({
      url: URL.createObjectURL(file),
      type: file.type,
      object: file,
    }));

    onFilesSelect(previews);
  };

  const handleButtonClick = () => {
    if (selectedFiles?.length) {
      onFilesSelect([]);
    }

    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div
      className={styles.inputIcon}
      onClick={handleButtonClick}
      style={{ position: 'relative' }}
    >
      {Boolean(selectedFiles?.length) && (
        <Tooltip title='Pasirinktų failų skaičius'>
          <div className={styles.selectedFilesCounter}>
            {selectedFiles?.length ?? 0}
          </div>
        </Tooltip>
      )}
      <img src={fileUploadIcon} alt='fileUpload' />
      {/* {Boolean(selectedFiles?.length) && (
        <div className={styles.deselectFiles} onClick={handleFileDestroy}>
          x
        </div>
      )} */}
      <input
        ref={fileInputRef}
        type='file'
        multiple
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </div>
  );
};
