import { useContext, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../global/GlobalStore';
import MessageInput from './MessageInput';
import { Skeleton } from '@mui/material';
import { useAsync } from 'react-use';
import {
  seenMessage,
  setChatRoomMessages,
  setData as setMessageCenterData,
} from '../../../../global/reducers/MessageCenterReducer';
import request, { handleScrollTo } from '../../../../global/helpers/utils';

import styles from '../styles/MessageChat.module.scss';
import { buildMessage } from './MessagesBuilder';
import _ from 'lodash';
import {
  SOCKET_ACTIONS,
  SocketContext,
} from '../../../../global/helpers/socket_context';

const MessageChat: React.FC = () => {
  const { activeChatRoom, isLoadingMessages } = useAppSelector(
    (state) => state.messageCenter
  );
  const dispatch = useAppDispatch();
  const socket = useContext(SocketContext);

  useAsync(async () => {
    if (!activeChatRoom || activeChatRoom.isAlreadyFullyLoaded) {
      return;
    }

    dispatch(setMessageCenterData({ isLoadingMessages: true }));
    // fetch messages

    const response = await request<{ messages: any[] }>({
      method: 'GET',
      url: `/v3/private/chat-room/${activeChatRoom?.id}/messages`,
    });

    // map messages to redux
    dispatch(
      setChatRoomMessages({
        chatRoomId: activeChatRoom.id,
        messages: response.messages,
      })
    );

    if (response.messages.length) {
      const lastMessage = response.messages[response.messages.length - 1];

      handleScrollTo(`referenceMessage_${lastMessage.id}`, true);

      socket?.emit(SOCKET_ACTIONS.USER_SEEN_MESSAGE, {
        chatRoomId: activeChatRoom.id,
        messageId: lastMessage.id,
      });

      dispatch(
        seenMessage({
          chatRoomId: activeChatRoom.id,
          messageId: lastMessage.id,
        })
      );
    }

    dispatch(setMessageCenterData({ isLoadingMessages: false }));
  }, [activeChatRoom?.id]);

  const messages = useMemo(() => {
    const userMap = _.keyBy(activeChatRoom?.users, (u) => Number(u.id));

    return (
      <div className={styles.chatMessagesContainer}>
        {activeChatRoom?.messages?.map((m, index) =>
          buildMessage(m, index, userMap[m.userId], dispatch)
        )}
      </div>
    );
  }, [activeChatRoom?.messages]);

  useEffect(() => {
    const lastMessage =
      activeChatRoom?.messages?.[activeChatRoom.messages.length - 1];

    if (!lastMessage) {
      return;
    }

    handleScrollTo(`referenceMessage_${lastMessage.id}`, true, false);
    socket?.emit(SOCKET_ACTIONS.USER_SEEN_MESSAGE, {
      chatRoomId: activeChatRoom.id,
      messageId: lastMessage.id,
    });

    dispatch(
      seenMessage({ chatRoomId: activeChatRoom.id, messageId: lastMessage.id })
    );
  }, [messages, activeChatRoom?.messages]);

  if (isLoadingMessages) {
    return (
      <div className={styles.mob_placeholder}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <br />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    );
  }

  return (
    <div className={styles.chatContainer}>
      {messages}
      <MessageInput />
    </div>
  );
};

export default MessageChat;
