import { useMemo } from 'react';
import styles from './styles/MessageWindow.module.scss';
import { useAppSelector } from '../../../global/GlobalStore';
import { USER_PERMISSIONS } from '@web-app/common';

import reviewIcon from '../../../assets/icons/like-shapes.svg';
import MessageChat from './components/MessageChat';

const MessageWindow: React.FC = () => {
  const { activeChatRoom } = useAppSelector((state) => state.messageCenter);
  const userData = useAppSelector((state) => state.user);

  const otherUserData = useMemo(() => {
    // support only 1-1 chat
    const user = activeChatRoom?.users?.find((u) => u.id !== userData.id);
    return {
      name: user?.name,
      rating: user?.rating,
      ratingCount: user?.ratingCount,
    };
  }, [activeChatRoom, userData]);

  const underTitle = useMemo(() => {
    // reverse role here
    const userType = userData.permissions.includes(USER_PERMISSIONS.worker)
      ? 'Klientas'
      : 'Meistras';

    const chatRoomCreatedOn = activeChatRoom
      ? new Date(activeChatRoom.createdOn)
      : new Date();

    return (
      <div className={styles.chatUnderTitle}>
        {userType} nuo:{' '}
        <span>
          {chatRoomCreatedOn.toLocaleDateString('Lt-lt', {
            month: 'short',
            year: 'numeric',
          })}
        </span>
      </div>
    );
  }, []);

  if (!activeChatRoom) {
    return null;
  }

  return (
    <div className={styles.windowContainer}>
      <div className={styles.windowHeader}>
        <p className={styles.chatTitle}>{otherUserData.name}</p>
        <div className={styles.chatAuxilary}>
          {underTitle}
          {(otherUserData?.ratingCount ?? 0) > 0 && (
            <>
              <img
                src={reviewIcon}
                alt='review-icon'
                className={styles.ratingIcon}
              />
              <div className={styles.ratingContainer}>
                {otherUserData.rating}{' '}
                <span>({otherUserData.ratingCount})</span>
              </div>
            </>
          )}
        </div>
      </div>
      <MessageChat />
    </div>
  );
};
export default MessageWindow;
