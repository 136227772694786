import Grid2 from '@mui/material/Unstable_Grid2';
import styles from './styles/RememberedServices.module.scss';
import _ from 'lodash';

import heartRedIcon from '../../../../assets/icons/heartRed.svg';
import { useState } from 'react';
import { Skeleton } from '@mui/material';
import { useAsync } from 'react-use';
import request from '../../../../global/helpers/utils';
import { useAppDispatch } from '../../../../global/GlobalStore';

import phServiceImageIcon from '../../../../assets/images/tattooPHImage.webp';
import phAvatarIcon from '../../../../assets/icons/avatar-placeholder.svg';

const RememberedServices: React.FC = () => {
  const dispatch = useAppDispatch();

  const [services, setServices] = useState<
    {
      serviceImageUrl: string;
      title: string;
      description: string;
      price?: number;
      author: {
        avatarImageUrl: string;
        name: string;
        city: string;
      };
    }[]
  >([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useAsync(async () => {
    setIsLoading(true);

    const result: any[] = await request(
      {
        method: 'GET',
        url: '/v3/private/remembered-services',
      },
      dispatch
    );

    if (result) {
      setCount(result?.length ?? 0);
      setServices(
        result?.map((r) => ({
          serviceImageUrl: r.serviceImageUrl ?? phServiceImageIcon,
          title: r.title ?? 'Neturi pavadinimo',
          description: r.description ?? 'Neturi aprašymo',
          price: r.price,
          author: {
            avatarImageUrl: r.avatarImageUrl ?? phAvatarIcon,
            name: r.authorName ?? 'Neturi vardo',
            city: r?.contactData?.city ?? r?.city,
          },
        })) ?? []
      );
    }

    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <div className={styles.container}>
        <h1>Įsiminti skelbimai</h1>
        <br />
        <Skeleton />
        <Skeleton />
        <br />
        <Skeleton />
        <Skeleton />
        <br />
        <Skeleton />
        <Skeleton />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h1>
        Įsiminti skelbimai <span>{count}</span>
      </h1>
      <Grid2 container gap={1} md={12}>
        {_.map(services, (service, index) => (
          <ServiceCard key={index} {...service} />
        ))}
      </Grid2>
    </div>
  );
};

export default RememberedServices;

type ServiceCardProps = {
  serviceImageUrl: string;
  title: string;
  description: string;
  price?: number;
  author: {
    avatarImageUrl: string;
    name: string;
    city: string;
  };
};

const ServiceCard: React.FC<ServiceCardProps> = (props) => {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.card}>
        <div className={styles.heartContainer}>
          <img src={heartRedIcon} alt='heart' />
        </div>
        <img
          src={props.serviceImageUrl}
          className={styles.serviceImg}
          alt='serviceImg'
        />
        <h2>{props.title}</h2>
        <p className={styles.cardDescription}>{props.description}</p>
        <div className={styles.bottomContainer}>
          <img src={props.author.avatarImageUrl} alt='avatar' />
          <p className={styles.authorName}>
            Įkelta <span>{props.author.name}</span>
          </p>
          <div className={styles.priceContainerOuter}>
            <p className={styles.priceContainer}>
              <span>€{props.price && props.price}</span>{' '}
              {props.price ? '/ Val' : 'Kaina sutartinė'}
            </p>
            <p className={styles.authorCity}>{props.author.city}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
