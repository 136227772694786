import { useMemo, useState } from 'react';
import styles from './styles/ProfileSettings.module.scss';
import _ from 'lodash';
import EditProfile from './EditProfile';
import EditPassword from './EditPassword';
import EditServices from './EditServices';
import DeactivateAccount from './DeactivateAccount';
import { useAppSelector } from '../../../../global/GlobalStore';
import { USER_PERMISSIONS } from '@web-app/common';

export enum ProfileSettingsSegments {
  PROFILE = 'Profilis',
  PASSWORD = 'Slaptažodis',
  SERVICES = 'Paslaugos',
  //   PAYMENT = 'Mokėjimo kortelė', -- kol kas nereikalingas
  DEACTIVATE_ACCOUNT = 'Deaktyvuoti paskyrą',
}

const ProfileSettings: React.FC = () => {
  const [currentSegment, setCurrentSegment] = useState(
    ProfileSettingsSegments.PROFILE
  );

  const { permissions } = useAppSelector((state) => state.user);

  const availableSegments = useMemo(() => {
    const isWorker = _.some(permissions, (p) => p === USER_PERMISSIONS.worker);

    const segments: ProfileSettingsSegments[] = [];

    segments.push(ProfileSettingsSegments.PROFILE);
    segments.push(ProfileSettingsSegments.PASSWORD);
    if (isWorker) segments.push(ProfileSettingsSegments.SERVICES);
    segments.push(ProfileSettingsSegments.DEACTIVATE_ACCOUNT);

    return segments;
  }, [permissions]);

  const segmentElement = useMemo(() => {
    switch (currentSegment) {
      case ProfileSettingsSegments.PROFILE:
        return <EditProfile />;
      case ProfileSettingsSegments.PASSWORD:
        return <EditPassword />;
      case ProfileSettingsSegments.SERVICES:
        return <EditServices />;
      case ProfileSettingsSegments.DEACTIVATE_ACCOUNT:
        return <DeactivateAccount />;

      default:
        return null;
    }
  }, [currentSegment]);

  return (
    <div className={styles.panel}>
      <div className={styles.leftContailer}>
        {_.map(availableSegments, (e, index) => (
          <button
            key={index}
            onClick={() => setCurrentSegment(e)}
            className={`${styles.navBtn} ${
              e === currentSegment ? styles.navBtn_active : ''
            }`}
          >
            {e}
          </button>
        ))}
      </div>
      <div className={styles.rightContailer}>{segmentElement}</div>
    </div>
  );
};

export default ProfileSettings;
