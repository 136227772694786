import {
  Message,
  User,
} from '../../../../global/reducers/MessageCenterReducer';

import styles from '../styles/MessageBuilder.module.scss';

import avatarPhIcon from '../../../../assets/icons/avatar-placeholder.svg';
import _ from 'lodash';
import request from '../../../../global/helpers/utils';

export const buildMessage = (
  message: Message,
  index: number,
  user: User,
  dispatch: any
) => {
  const filesHtml = message.metaData?.attachedFiles?.length
    ? messageWithFiles(message, dispatch)
    : null;

  if (message.metaData?.replyTo) {
  }

  return (
    <div
      id={`referenceMessage_${message.id}`}
      key={index}
      className={styles.messageContainer}
    >
      <div className={styles.messageContainerTop}>
        <img
          src={user?.avatarUrl ?? avatarPhIcon}
          alt={`${user?.name ?? 'uknown'}_avatar`}
          className={styles.messageAvatar}
        />
        <div className={styles.messageTextContainer}>
          <div className={styles.messageTextHeadline}>
            <div className={styles.userNameText}>{user?.name}</div>
            <div className={styles.createdOnDate}>
              {new Date(message.createdOn).toLocaleTimeString('LT-lt', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: false,
              })}
            </div>
          </div>
          <div
            className={styles.messageText}
            dangerouslySetInnerHTML={{ __html: message?.message }}
          />
        </div>
      </div>
      {filesHtml}
    </div>
  );
};

const messageWithFiles = (message: Message, dispatch: any) => {
  const files = message?.metaData?.attachedFiles;

  if (!files) {
    return null;
  }

  return files.map((file, index) => {
    const strippedFileName = _.last(file.fileName?.split('/'));
    const handleDownload = async () => {
      await request(
        {
          method: 'GET',

          url: `/download/${strippedFileName}`,
          responseType: 'blob',
        },
        dispatch,
        false,
        () => {
          // nothing
        },
        file.originalFileName
      );
    };

    if (file.mimeType.includes('image')) {
      return (
        <div className={styles.fileContainer} key={index}>
          <img
            src={file.fileName}
            alt={file.originalFileName}
            className={styles.previewFile}
          />
          <button className={styles.downdloadLink} onClick={handleDownload}>
            {file.originalFileName}
          </button>
        </div>
      );
    }

    if (file.mimeType.includes('video')) {
      return (
        <div className={styles.fileContainer} key={index}>
          <video src={file.fileName} className={styles.previewFile} controls />
          <button className={styles.downdloadLink} onClick={handleDownload}>
            {file.originalFileName}
          </button>
        </div>
      );
    }

    return (
      <div className={styles.fileContainer} key={index}>
        <button className={styles.downdloadLink} onClick={handleDownload}>
          {file.originalFileName}
        </button>
      </div>
    );
  });
};

// const MessageWithReplyTo: React.FC<MessageBuilderProps> = ({
//   message,
//   index,
// }) => {
//   return <div></div>;
// };
