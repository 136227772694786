import 'react-multi-carousel/lib/styles.css';
import styles from './styles/Hero.module.scss';

import logo_big from '../../../../assets/logo_big.svg';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import _ from 'lodash';
import { useMemo } from 'react';

import hero_image from '../../../../assets/public/hero/hero_mock.webp';
import { useAppDispatch } from '../../../../global/GlobalStore';
import { setAuthenticationData } from '../../../../global/reducers/GlobalReducer';
import { FormSegment } from '../../login-page/LoginPage';
import { useNavigate } from 'react-router-dom';

const Hero: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClickLogin = () => {
    dispatch(setAuthenticationData({ segment: FormSegment.LOGIN }));
    navigate('/login');
  };

  const handleClickRegister = () => {
    dispatch(setAuthenticationData({ segment: FormSegment.REGISTER }));
    navigate('/login');
  };

  return (
    <div className={styles.heroContainer}>
      <div className={`${styles.container}`}>
        <div className={styles.logoContainer}>
          <img src={logo_big} alt='logo' />
        </div>
        <p className={styles.heroTitle}>
          Greitai ir patogiai susirask savo patinkanti tattoo{' '}
          <span>MEISTRĄ</span>
        </p>
        <p className={styles.heroUnderline}>
          Tinklo specializacija yra gan plati, bet pagrindinės kryptys - tai
          tatuiravimas ir auskarų vėrimas. Šioje srityje laikomės aukštų
          standartų. Daugelis specialistų iki šiol dirba netinkamomis sąlygomis
          ir nekokybiška įranga, nesilaiko saugumo taisyklių. O svarbiausia, be
          garantijų ir atsakomybės.
        </p>
        <div className={styles.hero_btn_container}>
          <button className={styles.btn_login} onClick={handleClickLogin}>
            Prisijungti
          </button>
          <button className={styles.btn_register} onClick={handleClickRegister}>
            Sukurti paskyrą
          </button>
        </div>
      </div>
      <div className={styles.container}>
        <HeroCarousel />
      </div>
    </div>
  );
};

export default Hero;

const carouselMock = [
  {
    image: hero_image,
    title: 'Aukščiausios kvalifikacijos meistrų!',
    titleUnderline: '120k +',
  },
  {
    image: hero_image,
    title: 'Aukščiausios kvalifikacijos meistrų!',
    titleUnderline: '120k +',
  },
  {
    image: hero_image,
    title: 'Aukščiausios kvalifikacijos meistrų!',
    titleUnderline: '120k +',
  },
];

const HeroCarousel: React.FC = () => {
  const responsive: ResponsiveType = useMemo(
    () => ({
      desktop: {
        breakpoint: {
          min: 1370,
          max: 3000,
        },
        items: 1,
      },
      tablet: {
        breakpoint: {
          min: 464,
          max: 1370,
        },
        items: 1,
      },
      mobile: {
        breakpoint: { max: 678, min: 0 },
        items: 1,
      },
    }),
    []
  );

  return (
    <>
      <Carousel
        removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
        responsive={responsive}
        swipeable
        autoPlay
        infinite
        autoPlaySpeed={5000}
        transitionDuration={500}
        slidesToSlide={1}
        minimumTouchDrag={80}
        pauseOnHover
        showDots
        // renderDotsOutside
        dotListClass={styles.carouselDotsList}
        customDot={<CustomDot />}
      >
        {_.map(carouselMock, (item, index) => (
          <CarouselItem
            key={index}
            image={item.image}
            title={item.title}
            titleUnderline={item.titleUnderline}
          />
        ))}
      </Carousel>
    </>
  );
};

type CarouselItemProps = {
  image: string;
  title?: string;
  titleUnderline?: string;
};

const CarouselItem: React.FC<CarouselItemProps> = ({
  image,
  title,
  titleUnderline,
}) => {
  return (
    <div
      style={{ backgroundImage: `url(${image})` }}
      className={styles.carouselItem}
    >
      <div className={styles.carouselItemDescription}>
        <p className={styles.carouselItemTitle}>{title}</p>
        <p className={styles.carouselItemUnderline}>{titleUnderline}</p>
      </div>
    </div>
  );
};

const CustomDot = ({ ...rest }) => {
  const { index, active, onClick } = rest;

  return (
    <div
      onClick={onClick}
      key={index}
      className={active ? styles.carouselDot_active : styles.carouselDot}
    />
  );
};
