import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationType } from '../helpers/constants';

export interface NotificationState {
  notifications: NotificationItem[];
  messageNotifications: NotificationItem[]; // only NotificationType.UNREAD_MESSAGE
}

export type NotificationData = {
  userName?: string;
};

export type NotificationItem = {
  id: number;
  userId: number;
  notificationType: NotificationType;
  data: NotificationData;
  createdOn: string; // ISO date string reducer cannot parse
};

const initialState: NotificationState = {
  notifications: [],
  messageNotifications: [],
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: initialState,
  reducers: {
    setData: (state, action: PayloadAction<Partial<NotificationState>>) => {
      state = { ...state, ...action.payload };

      return state;
    },
    addNotification: (state, action: PayloadAction<NotificationItem>) => {
      if (action.payload.notificationType !== NotificationType.UNREAD_MESSAGE) {
        state.notifications.push(action.payload);
      }
      return state;
    },
  },
});

export default notificationSlice.reducer;
export const { setData, addNotification } = notificationSlice.actions;
