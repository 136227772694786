import 'react-multi-carousel/lib/styles.css';
import styles from './styles/Reviews.module.scss';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import { useMemo } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../global/GlobalStore';
import { setAuthenticationData } from '../../../../global/reducers/GlobalReducer';
import { FormSegment } from '../../login-page/LoginPage';

import arrow_left from '../../../../assets/icons/arrow-left.svg';

const Reviews: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleNavigate = () => {
    dispatch(setAuthenticationData({ segment: FormSegment.LOGIN }));
    navigate('/login');
  };

  return (
    <div className={styles.reviewsContainer}>
      <div className={styles.textContainer}>
        <p className={styles.headline}>Štai kodėl klientai mus myli</p>
        <p className={styles.underline}>
          Išplėskite savo klientų ratą ir padidinkite pajamas teikdami
          plačiausių meistrų skelbimų portale Lietuvoje !
        </p>
        <button className={styles.moreBtn} onClick={handleNavigate}>
          Skaityti daugiau atsiliepimų
        </button>
      </div>
      <div className={styles.verticalDivider} />
      <div className={styles.carouselContainer}>
        <ReviewCarousel />
      </div>
    </div>
  );
};

export default Reviews;

const mockData = [
  {
    comment:
      '„The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.“',
    author: 'ui.omka',
    authorTitle: '1st meistras Vilniuje',
  },
  {
    comment:
      '„The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.“',
    author: 'ui.omka',
    authorTitle: '1st meistras Vilniuje',
  },
  {
    comment:
      '„The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.“',
    author: 'ui.omka',
    authorTitle: '1st meistras Vilniuje',
  },
];

const ReviewCarousel: React.FC = () => {
  const responsive: ResponsiveType = useMemo(
    () => ({
      desktop: {
        breakpoint: {
          min: 1370,
          max: 3000,
        },
        items: 1,
      },
      tablet: {
        breakpoint: {
          min: 464,
          max: 1370,
        },
        items: 1,
      },
      mobile: {
        breakpoint: { max: 600, min: 0 },
        items: 1,
      },
    }),
    []
  );

  return (
    <Carousel
      responsive={responsive}
      swipeable
      autoPlay
      infinite
      autoPlaySpeed={5000}
      transitionDuration={500}
      slidesToSlide={1}
      minimumTouchDrag={80}
      pauseOnHover
      arrows={false}
      customButtonGroup={<ArrowGroup />}
      // renderButtonGroupOutside
    >
      {_.map(mockData, (data, index) => (
        <CarouselItem
          comment={data.comment}
          author={data.author}
          authorTitle={data.authorTitle}
          key={index}
        />
      ))}
    </Carousel>
  );
};

type CarouselItemProps = {
  comment?: string;
  author?: string;
  authorTitle?: string;
};

const CarouselItem: React.FC<CarouselItemProps> = ({
  comment,
  author,
  authorTitle,
}) => {
  return (
    <div className={styles.cardItem}>
      <p className={styles.cardItemComment}>{comment}</p>
      <div className={styles.divider} />
      <div className={styles.cardItemAuthorBox}>
        <p className={styles.cardItemAuthor}>{author}</p>
        <p className={styles.cardItemAuthorTitle}>{authorTitle}</p>
      </div>
    </div>
  );
};

const ArrowGroup: React.FC<any> = ({ next, previous }) => {
  return (
    <div className={styles.arrowGroup}>
      <button className={styles.arrowLeft} onClick={previous}>
        <img src={arrow_left} alt='arrow_left' />
      </button>
      <button className={styles.arrowRight} onClick={next}>
        <img src={arrow_left} alt='arrow_left' />
      </button>
    </div>
  );
};
