import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

export enum SORT_OPTIONS {
  NEWEST = 'Naujausi skelbimai',
  CHEAPEST = 'Pigiausi viršuje',
  MOST_EXPENSIVE = 'Brangiausi viršuje',
}

export interface ServiceListState {
  // possible filter options
  cityOptions: string[];
  experienceOptions: string[];
  priceOptions: number[];

  // current filter values
  selectedCity?: string;
  selectedExperience?: string;
  selectedPriceRange?: {
    min: number;
    max: number;
  };
  currentPage?: number;

  selectedUserId?: number;
  selectedUserName?: string;

  // handles from other pages
  otherPageSetFilter?: boolean;

  orderBy: SORT_OPTIONS;

  searchResults?: number;

  rememberedServiceIds: number[];

  // loader events
  filtersLoaded: boolean;
  listLoaded: boolean;
}

const initialState: ServiceListState = {
  cityOptions: [],
  experienceOptions: [],
  priceOptions: [],

  rememberedServiceIds: [],

  orderBy: SORT_OPTIONS.NEWEST,
  filtersLoaded: false,
  listLoaded: false,
};

export const serviceListSlice = createSlice({
  name: 'serviceList',
  initialState: initialState,
  reducers: {
    setData: (state, action: PayloadAction<Partial<ServiceListState>>) => {
      state = { ...state, ...action.payload };
      return state;
    },
    setMinMaxPrice: (
      state,
      action: PayloadAction<{ min?: number; max?: number }>
    ) => {
      state.selectedPriceRange = {
        min: !_.isNil(action.payload.min)
          ? action.payload.min
          : state.selectedPriceRange?.min ??
            _.min(state.priceOptions ?? []) ??
            0,
        max: !_.isNil(action.payload.max)
          ? action.payload.max
          : state.selectedPriceRange?.max ??
            _.max(state.priceOptions ?? []) ??
            0,
      };

      return state;
    },
    reset: (state) => {
      if (state.otherPageSetFilter) {
        // when using other pages we do not reset instead we set always brand new filter
        state.otherPageSetFilter = false;

        return state;
      }
      // state = initialState;
      state.selectedCity = undefined;
      state.selectedExperience = undefined;
      state.selectedPriceRange = undefined;
      state.selectedUserId = undefined;
      state.selectedUserName = undefined;

      return state;
    },
  },
});

export default serviceListSlice.reducer;
export const { setData, reset, setMinMaxPrice } = serviceListSlice.actions;
