"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdditionalServicesText = exports.socketEventsMap = exports.NotificationType = exports.ADDITIONAL_SERVICES = exports.USER_PERMISSIONS = void 0;
var socketEventsMap = {
    connect: 'connect',
};
exports.socketEventsMap = socketEventsMap;
var USER_PERMISSIONS;
(function (USER_PERMISSIONS) {
    USER_PERMISSIONS["user"] = "user";
    USER_PERMISSIONS["worker"] = "worker";
    USER_PERMISSIONS["admin"] = "admin";
})(USER_PERMISSIONS || (exports.USER_PERMISSIONS = USER_PERMISSIONS = {}));
var ADDITIONAL_SERVICES;
(function (ADDITIONAL_SERVICES) {
    ADDITIONAL_SERVICES[ADDITIONAL_SERVICES["EAR_PIERCING"] = 1] = "EAR_PIERCING";
    ADDITIONAL_SERVICES[ADDITIONAL_SERVICES["SKECTCH_DRAW"] = 2] = "SKECTCH_DRAW";
    ADDITIONAL_SERVICES[ADDITIONAL_SERVICES["EYE_LASHES_GROW"] = 3] = "EYE_LASHES_GROW";
    ADDITIONAL_SERVICES[ADDITIONAL_SERVICES["TATTOO_REMOVAL"] = 4] = "TATTOO_REMOVAL";
    ADDITIONAL_SERVICES[ADDITIONAL_SERVICES["OTHER_SERVICES"] = 5] = "OTHER_SERVICES";
})(ADDITIONAL_SERVICES || (exports.ADDITIONAL_SERVICES = ADDITIONAL_SERVICES = {}));
var AdditionalServicesText = (_a = {},
    _a[ADDITIONAL_SERVICES.EAR_PIERCING] = 'Auskarų vėrimas',
    _a[ADDITIONAL_SERVICES.SKECTCH_DRAW] = 'Eskizų piešimas',
    _a[ADDITIONAL_SERVICES.EYE_LASHES_GROW] = 'Blakstienų priauginimas',
    _a[ADDITIONAL_SERVICES.TATTOO_REMOVAL] = 'Tatuiruočių naikinimas',
    _a[ADDITIONAL_SERVICES.OTHER_SERVICES] = 'Kitos paslaugos',
    _a);
exports.AdditionalServicesText = AdditionalServicesText;
var NotificationType;
(function (NotificationType) {
    // profile notifications
    NotificationType[NotificationType["COMMENT_PROFILE"] = 1] = "COMMENT_PROFILE";
    NotificationType[NotificationType["LIKED_ADVERTISEMENT"] = 2] = "LIKED_ADVERTISEMENT";
    // message notifications
    NotificationType[NotificationType["UNREAD_MESSAGE"] = 3] = "UNREAD_MESSAGE";
})(NotificationType || (exports.NotificationType = NotificationType = {}));
