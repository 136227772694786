import { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../global/GlobalStore';

import styles from './styles/ServicesFilter.module.scss';
import Menu from '@mui/material/Menu';
import { MenuItem } from '@mui/material';
import _ from 'lodash';

import locationIcon from '../../../../assets/icons/location.svg';
import arrowDownIcon from '../../../../assets/icons/arrow-down.svg';
// import medalIcon from '../../../../assets/icons/medal-star.svg';
import {
  setData,
  setMinMaxPrice,
} from '../../../../global/reducers/ServiceListReducer';
import { MultiRangeSlider } from '../../../../components/RangeSlider/MultiRangeSlider';

const ServicesFilter: React.FC = () => {
  return (
    <div className={styles.container}>
      <CityFilterDropdown />
      <div className={styles.verticalDivider} />
      {/* <ExperienceFilterDropdown />
      <div className={styles.verticalDivider} /> */}
      <PriceFilter />
    </div>
  );
};

export default ServicesFilter;

const CityFilterDropdown: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { cityOptions, selectedCity } = useAppSelector(
    (state) => state.serviceList
  );

  const dispatch = useAppDispatch();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!cityOptions.length) return;
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <button onClick={handleMenuClick} className={styles.cityBtn}>
        <div className={styles.icon}>
          <img alt='location' src={locationIcon} />
        </div>
        <span>{selectedCity ?? 'Ieškoti pagal miestą'}</span>
        <img
          alt='arrow-down'
          src={arrowDownIcon}
          className={styles.arrowIcon}
        />
      </button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {_.map(cityOptions, (option, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              dispatch(setData({ selectedCity: option }));
              setAnchorEl(null);
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

// const ExperienceFilterDropdown: React.FC = () => {
//   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
//   const { experienceOptions, selectedExperience } = useAppSelector(
//     (state) => state.serviceList
//   );

//   const dispatch = useAppDispatch();

//   const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//     if (experienceOptions.length === 0) return;

//     setAnchorEl(event.currentTarget);
//   };

//   return (
//     <>
//       <button onClick={handleMenuClick} className={styles.cityBtn}>
//         <div className={styles.icon}>
//           <img alt='location' src={medalIcon} />
//         </div>
//         <span>{selectedExperience ?? 'Meistrų patirtis'}</span>
//         <img
//           alt='arrow-down'
//           src={arrowDownIcon}
//           className={styles.arrowIcon}
//         />
//       </button>
//       <Menu
//         anchorEl={anchorEl}
//         open={Boolean(anchorEl)}
//         onClose={() => setAnchorEl(null)}
//       >
//         {_.map(experienceOptions, (option, index) => (
//           <MenuItem
//             key={index}
//             onClick={() => {
//               dispatch(setData({ selectedExperience: option }));
//               setAnchorEl(null);
//             }}
//           >
//             {option}
//           </MenuItem>
//         ))}
//       </Menu>
//     </>
//   );
// };

const PriceFilter: React.FC = () => {
  const { selectedPriceRange, priceOptions } = useAppSelector(
    (state) => state.serviceList
  );

  const dispatch = useAppDispatch();

  const minPrice = useMemo(
    () => Math.min(...priceOptions) ?? 0,
    [priceOptions]
  );
  const maxPrice = useMemo(() => Math.max(...priceOptions), [priceOptions]);

  const slider = useMemo(() => {
    return (
      <MultiRangeSlider
        min={minPrice}
        max={maxPrice}
        onChange={(v) => dispatch(setMinMaxPrice({ min: v.min, max: v.max }))}
        current={{
          min: selectedPriceRange?.min ?? minPrice,
          max: selectedPriceRange?.max ?? maxPrice,
        }}
      />
    );
  }, [minPrice, maxPrice, dispatch, selectedPriceRange]);

  return (
    <div className={styles.rangeContainer}>
      <div className={styles.range}>
        <span className={styles.rangeTitle}>Kaina per valanda</span>
        <span className={styles.rangeUnder}>
          €{selectedPriceRange?.min ?? 0} - €{selectedPriceRange?.max ?? 0}
        </span>
      </div>
      {slider}
    </div>
  );
};
