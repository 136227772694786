import styles from './styles/UserServicesList.module.scss';

import imagePh from '../../../../assets/images/tattooPHImage.webp';
import { Fragment } from 'react/jsx-runtime';
import { useState } from 'react';
import { Skeleton } from '@mui/material';
import { useAsync } from 'react-use';
import { useAppDispatch, useAppSelector } from '../../../../global/GlobalStore';
import request from '../../../../global/helpers/utils';
import {
  setCurrentMainPanelSegment,
  setGlobalLoading,
} from '../../../../global/reducers/GlobalReducer';
import { MainPanelSegments } from '../../../../global/helpers/constants';
import { setData } from '../../../../global/reducers/ServiceListReducer';

const UserServicesList: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { userId } = useAppSelector((state) => state.profile);
  const { id } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const [servicesCount, setServicesCount] = useState(0);
  const [services, setServices] = useState<
    | {
        id: number;
        serviceImageUrl?: string;
        title: string;
        description: string;
        price?: number;
        city?: string;
      }[]
    | null
  >(null);

  useAsync(async () => {
    if (!userId && !id) return;

    setIsLoading(true);

    const result: {
      data: any[];
      servicesCount: number;
    } = await request(
      {
        method: 'POST',
        url: `/v3/private/service/search`,
        data: {
          itemCount: 2,
          userId: userId ?? id,
        },
      },
      dispatch
    );

    setServicesCount(result.servicesCount ?? 0);
    setServices(
      result.data.map((d) => ({
        id: d.id,
        title: d.name ?? 'Neturi pavadinimo',
        description: d.description,
        price: d.data?.customPrice ? undefined : d.price ?? 0,
        city: d.contactData?.city ?? 'Nenurodyta',
        serviceImageUrl: d.imageUrl ?? undefined,
      }))
    );

    setIsLoading(false);
  }, [userId, id]);

  const handleShowMore = async () => {
    // go to all advertisements with userId filter
    dispatch(setGlobalLoading(true));
    dispatch(
      setData({
        selectedUserId: userId ?? id ?? undefined,
        selectedCity: undefined,
        selectedExperience: undefined,
        selectedPriceRange: undefined,
        selectedUserName: undefined,
        otherPageSetFilter: true,
      })
    );
    dispatch(
      setCurrentMainPanelSegment({ segment: MainPanelSegments.USER_SERVICES })
    );
  };

  if (isLoading) {
    return (
      <div className={styles.servicesListContainer}>
        <Skeleton height={'200px'} />
        <Skeleton />
        <Skeleton height={'150px'} />
        <Skeleton />
      </div>
    );
  }

  if (servicesCount < 1) {
    return;
  }

  return (
    <div className={styles.servicesListContainer}>
      <div className={styles.servicesListContainerInner}>
        <h2>Įkelti skelbimai</h2>
        {(services ?? []).map((service, index) => (
          <Fragment key={`${index}-fragment`}>
            <div className={styles.divider} key={`${index}-divider`} />
            <div key={`${index}-children`}>
              <img
                className={styles.serviceImage}
                src={service.serviceImageUrl ?? imagePh}
                alt='serviceIcon'
              />
              <h3 className={styles.serviceTitle}>{service.title}</h3>
              <p className={styles.serviceDescription}>{service.description}</p>
              <p className={styles.servicePrice}>
                <span>€{service.price ?? ''}</span>{' '}
                {service.price ? '/ Val' : '/ Kaina sutartinė'}
              </p>
              <p className={styles.serviceCity}>{service.city}</p>
            </div>
          </Fragment>
        ))}
        {servicesCount > 2 && (
          <button className={styles.showMoreBtn} onClick={handleShowMore}>
            Rodyti daugiau
          </button>
        )}
      </div>
    </div>
  );
};

export default UserServicesList;
