import React, { useState } from 'react';

import styles from '../LoginPage.module.scss';

import mail_icon from '../../../../assets/icons/sms.svg';
import key_icon from '../../../../assets/icons/key.svg';
import request from '../../../../global/helpers/utils';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../global/GlobalStore';
import CircleLoader from '../../../../components/Loader/CircleLoader';
import { setCurrentMainPanelSegment } from '../../../../global/reducers/GlobalReducer';
import { MainPanelSegments } from '../../../../global/helpers/constants';

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async () => {
    // Add your login logic here
    setIsLoading(true);
    try {
      await request(
        {
          method: 'POST',
          url: '/auth/login',
          data: {
            email: email,
            password: password,
          },
        },
        dispatch,
        false,
        () => setIsLoading(false)
      );

      dispatch(
        setCurrentMainPanelSegment({ segment: MainPanelSegments.USER_SERVICES })
      );
      navigate('/panel');
    } catch (e) {
      // indicate somewhere is error
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <p className={styles.title}>Prisijunkite prie savo paskyros</p>
      <div className={styles.formContainer}>
        <div className={styles.basicInputBox}>
          <div
            style={{ backgroundImage: `url(${mail_icon})` }}
            className={styles.inputIcon}
          />
          <input
            className={styles.basicInput}
            placeholder='Jūsų el. paštas'
            type='email'
            id='email'
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <div className={styles.basicInputBox}>
          <div
            style={{ backgroundImage: `url(${key_icon})` }}
            className={styles.inputIcon}
          />
          <input
            className={styles.basicInput}
            placeholder='Slaptažodis'
            type='password'
            id='password'
            value={password}
            onChange={handlePasswordChange}
          />
        </div>
        <button
          type='submit'
          onClick={handleSubmit}
          className={styles.submitBtn}
          disabled={isLoading}
        >
          {isLoading ? <CircleLoader width={50} /> : 'Prisijungti'}
        </button>
      </div>
    </div>
  );
};

export default LoginForm;
