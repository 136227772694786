import { useRef } from 'react';

import 'react-range-slider-input/dist/style.css';
import RangeSlider from 'react-range-slider-input';
import styles from './MultiRangeSlider.module.scss';

type MultiRangeSliderProps = {
  min: number;
  max: number;
  onChange: ({ min, max }: { min: number; max: number }) => void;
  current: { min: number; max: number };
};

export const MultiRangeSlider: React.FC<MultiRangeSliderProps> = ({
  min,
  max,
  onChange,
  current,
}) => {
  const range = useRef<HTMLDivElement | null>(null);

  const handleChange = (v: number[]) => {
    onChange({ min: v[0], max: v[1] });

    return false;
  };

  return (
    <RangeSlider
      id={styles.slider}
      min={min}
      max={max}
      ref={range}
      onInput={handleChange}
      value={[current.min, current.max]}
    />
  );
};
