import styles from './CircleLoader.module.scss';

type CircleLoaderProps = {
  width?: number;
};

const CircleLoader: React.FC<CircleLoaderProps> = ({ width }) => (
  <div
    className={styles.container}
    style={{ width: width ? width : 'inherit', height: width ? width : '100%' }}
  >
    <svg viewBox='0 0 100 100'>
      <defs>
        <filter id={'shadow'} className={styles.shadow}>
          <feDropShadow
            dx='0'
            dy='0'
            stdDeviation='1.5'
            floodColor='#2A1AB8' // original color: #fc6767
          />
        </filter>
      </defs>
      <circle
        className={styles.spinner}
        style={{
          fill: 'transparent',
          stroke: '#796AFF', // original color: #dd2476
          strokeWidth: 7,
          strokeLinecap: 'round',
          filter: 'url(#shadow)',
        }}
        // style='fill:transparent;stroke:#dd2476;stroke-width: 7px;stroke-linecap: round;filter:url(#shadow);'
        cx='50'
        cy='50'
        r='45'
      />
    </svg>
  </div>
);

export default CircleLoader;
