import React, { useState } from 'react';

import styles from './Input.module.scss';

interface InputProps {
  label: string;
  onChange: (value: string) => void;
  value: any;
  type?: string;
  underline?: string;
  autocomplete?: React.HTMLInputAutoCompleteAttribute;
}

export const Input: React.FC<InputProps> = ({
  label,
  onChange,
  value,
  type = 'text',
  underline,
  autocomplete,
}) => {
  const [inputValue, setInputValue] = useState(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (onChange) {
      onChange(event.currentTarget.value);
    }

    setInputValue(event.currentTarget.value);
  };

  return (
    <form className={styles.inputA}>
      <p>{label}</p>
      <input
        onChange={handleChange}
        value={inputValue}
        type={type ?? 'text'}
        autoComplete={autocomplete}
      />
      {underline && <p className={styles.inputUnderline}>{underline}</p>}
    </form>
  );
};

type TextAreaInputProps = {
  label: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: any;
};

export const TextAreaInput: React.FC<TextAreaInputProps> = ({
  label,
  onChange,
  value,
}) => {
  return (
    <div className={styles.textAreaInput}>
      <p>{label}</p>
      <textarea onChange={onChange} value={value} rows={10} />
    </div>
  );
};
