import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../../global/GlobalStore';
import { NOTIFICATION_PAGE_SEGMENTS } from './NotificationsPanel';
import { NotificationItem } from '../../../global/reducers/NotificationReducer';
import { BellIcon } from '../../../components/Icon/Icon';
import { NotificationType } from '../../../global/helpers/constants';

import styles from './styles/NotificationList.module.scss';
import { AnimatePresence, motion } from 'framer-motion';

const NotificationList: React.FC<{ segment: NOTIFICATION_PAGE_SEGMENTS }> = ({
  segment,
}) => {
  const { notifications } = useAppSelector((state) => state.notification);
  const [filteredNotifications, setFilteredNotifications] =
    useState(notifications);

  useEffect(() => {
    switch (segment) {
      case NOTIFICATION_PAGE_SEGMENTS.ALL:
        setFilteredNotifications(notifications);
        break;
      case NOTIFICATION_PAGE_SEGMENTS.PROFILE_ACTIVITY:
        setFilteredNotifications(
          notifications.filter(
            (notification) =>
              notification.notificationType === NotificationType.COMMENT_PROFILE
          )
        );
        break;
      case NOTIFICATION_PAGE_SEGMENTS.SERVICE_ACTIVITY:
        setFilteredNotifications(
          notifications.filter(
            (notification) =>
              notification.notificationType ===
              NotificationType.LIKED_ADVERTISEMENT
          )
        );
        break;
    }
  }, [segment, notifications]);

  if (!notifications.length) {
    return (
      <div>
        <p>Nėra pranešimų</p>
      </div>
    );
  }

  return (
    <div>
      <AnimatePresence>
        {filteredNotifications.map((notification, index) => (
          <NotificationListItem
            key={index}
            notification={notification}
            index={index}
          />
        ))}
      </AnimatePresence>
    </div>
  );
};

export default NotificationList;

const NotificationListItem: React.FC<{
  notification: NotificationItem;
  index: number;
}> = ({ notification, index }) => {
  const notificationIcon = useMemo(() => {
    switch (notification.notificationType) {
      case NotificationType.COMMENT_PROFILE:
        return (
          <div
            className={`${styles.notificationIcon} ${styles.notificationIcon__comment}`}
          >
            {<BellIcon primary='#5D55FA' secondary='#5D55FA' />}
          </div>
        );
      case NotificationType.LIKED_ADVERTISEMENT:
        return (
          <div
            className={`${styles.notificationIcon} ${styles.notificationIcon__like}`}
          >
            {<BellIcon primary='#FF6A55' secondary='#FF6A55' />}
          </div>
        );
      default:
        return <BellIcon />;
    }
  }, [notification.notificationType]);

  const notificationTitle = useMemo(() => {
    switch (notification.notificationType) {
      case NotificationType.COMMENT_PROFILE:
        return 'Profilio veikla';
      case NotificationType.LIKED_ADVERTISEMENT:
        return 'Skelbimai';
    }
  }, [notification.notificationType]);

  const notificationText = useMemo(() => {
    switch (notification.notificationType) {
      case NotificationType.COMMENT_PROFILE:
        return (
          <>
            <span>{notification.data.userName}</span> Paliko apie jus
            atsiliepimą
          </>
        );
      case NotificationType.LIKED_ADVERTISEMENT:
        return (
          <>
            <span>{notification.data.userName}</span> Patiko jūsų skelbimas
          </>
        );
      case NotificationType.UNREAD_MESSAGE:
        return (
          <>
            Naujas pranešimas nuo <span>{notification.data.userName}</span>
          </>
        );
    }
  }, [notification.notificationType, notification.data.userName]);

  const calculatedDate = useMemo(() => {
    const elapsed =
      new Date().getTime() - new Date(notification.createdOn).getTime();

    if (elapsed < 1000 * 60 * 60) {
      return 'Prieš mažiau nei valandą';
    }

    if (elapsed < 1000 * 60 * 60 * 24) {
      const elapsedHours = Math.floor(elapsed / 1000 / 60 / 60);
      let text =
        elapsedHours === 1
          ? 'valandą'
          : elapsedHours > 9
          ? 'valandų'
          : 'valandas';

      return `Prieš ${Math.floor(elapsed / 1000 / 60 / 60)} ${text}`;
    }

    const elapsedDays = Math.floor(elapsed / 1000 / 60 / 60 / 24);
    let text =
      elapsedDays === 1 ? 'dieną' : elapsedDays > 9 ? 'dienų' : 'dienas';

    return `Prieš ${Math.floor(elapsed / 1000 / 60 / 60 / 24)} ${text}`;
  }, [notification.createdOn]);

  return (
    <motion.div
      layout
      initial={{
        opacity: 0.1,
        x: -100,
      }}
      whileInView={{
        opacity: 1,
        x: 0,
        transition: {
          duration: 0.5,
          delay: 0.05 + index * 0.1,
        },
      }}
      viewport={{ once: true }}
      className={styles.notificationItem}
    >
      <div className={styles.notificationLeftContainer}>
        {notificationIcon}
        <div>
          <p className={styles.notificationItemTitle}>{notificationTitle}</p>
          <p className={styles.notificationItemDescription}>
            {notificationText}
          </p>
        </div>
      </div>
      <div>
        <p className={styles.notificationItemDate}>{calculatedDate}</p>
      </div>
    </motion.div>
  );
};
