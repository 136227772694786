import styles from './styles/Footer.module.scss';
import logo from '../../../../assets/logo.webp';

import facebook_icon from '../../../../assets/public/footer/facebook.svg';
import instragram_icon from '../../../../assets/public/footer/instagram.svg';
import twitter_icon from '../../../../assets/public/footer/x.svg';
import linkedin_icon from '../../../../assets/public/footer/linkedin.svg';

const Footer: React.FC = () => {
  const handleLink = (url: string) => {
    window.open(url, '_blank');
    // window.location.href = url;
  };

  return (
    <div className={styles.container}>
      <div className={styles.upperContainer}>
        <div className={styles.logoContainer}>
          <div className={styles.logo}>
            <img alt='logo' src={logo} />
            <p>tatto</p>
          </div>
        </div>
        <div>
          <p className={styles.title}>Kontaktai</p>
          <p className={styles.underline}>Įmonė: Arkada projects, MB</p>
          <p className={styles.underline}>Įmonės kodas: 306962527</p>
          <p className={styles.underline}>
            Adresas: Perkūnkiemio g. 19, LT-12120 Vilnius
          </p>
          <p className={styles.underline}>Telefonas: +370 626 06869</p>
        </div>
        {/* 
        <div>
          <p className={styles.title}>Klientui</p>
          <p className={styles.underline}>Naudojimo sąlygos</p>
          <p className={styles.underline}>Licencijos sutartis</p>
          <p className={styles.underline}>Autorių teises</p>
          <p className={styles.underline}>Slapukų politika</p>
          <p className={styles.underline}>Tvarkyti nuostatas</p>
        </div> */}
      </div>
      <div className={styles.divider} />
      <div className={styles.lowerContainer}>
        <div className={styles.rightText}>
          © Tatto, {new Date().getFullYear()}. Visos teisės saugomos
        </div>
        <div>
          <button
            className={styles.iconBtn}
            onClick={() => handleLink('https://www.facebook.com/')}
          >
            <img src={facebook_icon} alt='facebook_icon' />
          </button>
          <button
            className={styles.iconBtn}
            onClick={() => handleLink('https://www.instagram.com/')}
          >
            <img src={instragram_icon} alt='instragram_icon' />
          </button>
          <button
            className={styles.iconBtn}
            onClick={() => handleLink('https://lt.linkedin.com/')}
          >
            <img src={linkedin_icon} alt='linkedin_icon' />
          </button>
          <button
            className={styles.iconBtn}
            onClick={() => handleLink('https://x.com/')}
          >
            <img src={twitter_icon} alt='twitter_icon' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
