import { useCallback, useMemo, useState } from 'react';
import styles from './styles/EditServices.module.scss';
import { ADDITIONAL_SERVICES, AdditionalServicesText } from '@web-app/common';
import { Checkbox, Skeleton } from '@mui/material';
import _ from 'lodash';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useAsync } from 'react-use';
import request from '../../../../global/helpers/utils';
import { useAppDispatch } from '../../../../global/GlobalStore';
import { displayNotification } from '../../../../global/reducers/GlobalReducer';

const EditServices: React.FC = () => {
  const dispatch = useAppDispatch();

  const [selectedService, setSelectedService] = useState<ADDITIONAL_SERVICES[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  useAsync(async () => {
    setIsLoading(true);

    const response: string[] | null = await request({
      method: 'GET',
      url: '/v3/private/user/edit/additional-services',
    });

    setSelectedService(response?.map((s) => Number(s)) ?? []);

    setIsLoading(false);
  }, []);

  const handleSelectedCheckbox = useCallback(
    (service: ADDITIONAL_SERVICES) => {
      const selected = new Set<ADDITIONAL_SERVICES>(selectedService);

      if (selected.has(service)) {
        selected.delete(service);
      } else {
        selected.add(service);
      }

      setSelectedService(Array.from(selected));
    },
    [selectedService]
  );

  const checkboxArray = useMemo(() => {
    return _.map(
      ADDITIONAL_SERVICES,
      (service, index) =>
        _.isNumber(service) && (
          <div key={`checkbox-${index}`} className={styles.checkboxInput}>
            <Checkbox
              classes={{
                checked: styles.inputChecked,
                root: styles.inputCheckedRoot,
              }}
              value={service}
              checked={_.some(selectedService, (s) => s === service)}
              onClick={() => handleSelectedCheckbox(service)}
            />
            <p>{AdditionalServicesText[service]}</p>
          </div>
        )
    );
  }, [selectedService, handleSelectedCheckbox]);

  const handleSubmit = async () => {
    setIsDisabled(true);

    await request(
      {
        method: 'POST',
        url: '/v3/private/user/edit/additional-services',
        data: {
          services: selectedService,
        },
      },
      dispatch,
      false,
      () => setIsDisabled(false)
    );

    dispatch(
      displayNotification({
        message: 'Informacija atnaujinta!',
        severity: 'success',
      })
    );

    setIsDisabled(false);
  };

  if (isLoading) {
    return (
      <div className={styles.container}>
        <p className={styles.headline}>
          Pridėti papildomas teikiamas paslaugas
        </p>
        <div className={styles.spacer} />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <p className={styles.headline}>Pridėti papildomas teikiamas paslaugas</p>
      <div className={styles.spacer} />
      <div className={styles.headlineContainer}>
        <p>Pasirinkite papildomas teikiamas paslaugas</p>
        <Grid2 container rowGap={'37px'} columnGap={'25px'}>
          {checkboxArray}
        </Grid2>
      </div>
      <button
        className={styles.submitBtn}
        disabled={isDisabled || isLoading}
        onClick={handleSubmit}
      >
        Išsaugoti
      </button>
    </div>
  );
};

export default EditServices;
