import { Backdrop, Modal } from '@mui/material';

import styles from './styles/ServiceModal.module.scss';
import { useAppSelector, useAppDispatch } from '../../../../global/GlobalStore';

// icons
import reviewIcon from '../../../../assets/icons/like-shapes.svg';
import defaultAvatar from '../../../../assets/icons/avatar-placeholder.svg';
import defaultServiceImage from '../../../../assets/images/tattooPHImage.webp';
import callingIcon from '../../../../assets/icons/call-calling.svg';
import calendarTickIcon from '../../../../assets/icons/calendar-tick.svg';
import messageIcon from '../../../../assets/icons/message.svg';
import editIcon from '../../../../assets/icons/edit.svg';
import exitIcon from '../../../../assets/icons/add.svg';
import deleteIcon from '../../../../assets/moving-icons/trash.gif';
import heartIcon from '../../../../assets/icons/heartRed.svg';
//
import { useAsync, useMount } from 'react-use';
import {
  closeModal,
  handleLightBox,
} from '../../../../global/reducers/ServiceModalReducer';
import { setData } from '../../../../global/reducers/ServiceAdjustReducer';
import { setData as setModalData } from '../../../../global/reducers/ServiceModalReducer';
import { setData as setProfileData } from '../../../../global/reducers/ProfileReducer';
import { setData as setServiceListData } from '../../../../global/reducers/ServiceListReducer';
import {
  displayNotification,
  setCurrentMainPanelSegment,
} from '../../../../global/reducers/GlobalReducer';
import { MainPanelSegments } from '../../../../global/helpers/constants';
import { ChangeEvent, Fragment, useMemo, useState } from 'react';
import _ from 'lodash';
import { AdditionalServicesText, USER_PERMISSIONS } from '@web-app/common';
import CircleLoader from '../../../../components/Loader/CircleLoader';
import request from '../../../../global/helpers/utils';
import { AnimatePresence, motion } from 'framer-motion';
import {
  persistChatRoom,
  setActiveChatRoom,
} from '../../../../global/reducers/MessageCenterReducer';

const ServiceModal: React.FC = () => {
  const { isOpen, selectedServiceId, isPreviewMode, isCommentsOpen, authorId } =
    useAppSelector((state) => state.serviceModal);
  const dispatch = useAppDispatch();
  const { id, permissions } = useAppSelector((state) => state.user);

  const isEditEnabled = useMemo(
    () =>
      !isPreviewMode &&
      id === authorId &&
      id !== null &&
      _.some(
        permissions,
        (p) => p === USER_PERMISSIONS.worker || p === USER_PERMISSIONS.admin
      ),
    [isPreviewMode, id, permissions, authorId]
  );

  useAsync(async () => {
    if (isPreviewMode) {
      // used in edit service page
      return;
    }

    if (selectedServiceId) {
      const result = await request(
        {
          method: 'GET',
          url: '/v3/private/service',
          params: {
            serviceId: selectedServiceId,
          },
        },
        dispatch,
        false,
        () => {
          dispatch(
            setModalData({
              isOpen: false,
            })
          );
        }
      );

      // return all comments for user

      const resultComments: { comments: any[] } = await request(
        {
          method: 'GET',
          url: '/v3/private/comment',
          params: {
            id: result.user.id,
          },
        },
        dispatch
      );

      dispatch(
        setModalData({
          authorId: result.user.id,
          authorName: result.user.name,
          authorImageUrl: result.user.avatarFileName,
          authorRating: result.user.rating,
          authorReviewCount: result.user.ratingCount,

          title: result.service.name,

          city: result.service.contactData.city,
          address: result.service.contactData.address,
          phone: result.service.contactData.phone,
          services: result.service.services,
          description: result.service.description,
          price: result.service?.data?.customPrice
            ? undefined
            : result.service.price,

          serviceImageUrl: result.service.imageUrl,

          comments:
            resultComments.comments.map((r) => ({
              id: r.id,
              comment: r.comment,
              rating: r.rating,
              authorName: r.name,
              authorImageUrl: r.userAvatarUrl,
              updatedOn: r.commentDate,
              ownerId: r.userId,
            })) ?? [],

          files: result.service.fileData,

          likeCount: result.remmembersService ?? 0,

          isLoading: false,
        })
      );
      // fetch service data to present in modal
      // check if user is owner of this service
      // if user is owner, show delete/edit button
    }
  }, [selectedServiceId, isOpen]);

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleDelete = async () => {
    // delete service if owner clicks on this
    if (selectedServiceId) {
      await request(
        {
          method: 'DELETE',
          url: '/v3/private/service',
          data: {
            serviceId: selectedServiceId,
          },
        },
        dispatch,
        true
      );

      // remove it from list
      dispatch(
        setServiceListData({
          currentPage: undefined,
          selectedCity: undefined,
          listLoaded: false,
        })
      );
      dispatch(closeModal());
      dispatch(
        displayNotification({
          severity: 'success',
          message: 'Paslauga ištrinta',
        })
      );
    }
  };

  const handleMoveToEdit = () => {
    // move to edit service page
    if (selectedServiceId) {
      dispatch(setData({ serviceId: selectedServiceId }));
      dispatch(
        setCurrentMainPanelSegment({
          segment: MainPanelSegments.ADJUST_SERVICE,
          serviceId: selectedServiceId,
        })
      );
      dispatch(closeModal());
    }
  };

  return (
    <Modal open={isOpen} onClose={handleClose} className={styles.modal}>
      <div className={styles.modalContainer}>
        <AnimatePresence mode='wait'>
          <motion.div className={styles.container} layout>
            <div className={styles.btnContainer}>
              {isEditEnabled && (
                <>
                  <button onClick={handleMoveToEdit}>
                    <img src={editIcon} alt='edit' />
                  </button>
                  <button onClick={handleDelete}>
                    <img src={deleteIcon} alt='delete' />
                  </button>
                </>
              )}
              <button onClick={handleClose}>
                <img src={exitIcon} alt='delete' />
              </button>
            </div>

            <ServiceModalMainSegment />
            {isCommentsOpen && (
              <>
                <div className={styles.verticalDivider} />
                <ServiceModalCommentSegment />
              </>
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    </Modal>
  );
};

export default ServiceModal;

const ServiceModalMainSegment: React.FC = () => {
  const {
    selectedServiceId,
    likeCount,
    title,
    isPreviewMode,
    isCommentsOpen,
    isLoading,
    serviceImageUrl,
    price,
    city,
    address,
    phone,
    description,
    services, // additionalservices

    files,
  } = useAppSelector((state) => state.serviceModal);
  const {
    authorImageUrl,
    authorName,
    authorRating,
    authorReviewCount,
    authorId,
  } = useAppSelector((state) => state.serviceModal);
  const user = useAppSelector((state) => state.user);
  const [isDisabled, setIsDisabled] = useState(false);
  const dispatch = useAppDispatch();

  const handleSwitchComments = (enableComments: boolean) => {
    // switch between comments and main
    dispatch(setModalData({ isCommentsOpen: enableComments }));
  };

  const handleOpenMap = () => {
    if (!address) return;

    window.open(
      `https://google.com.sa/maps/search/${address}+${city}`,
      '_blank'
    );
  };

  const handleOpenUserProfile = () => {
    // open profile but for this user
    dispatch(setProfileData({ userId: authorId }));
    dispatch(
      setCurrentMainPanelSegment({
        segment: MainPanelSegments.PROFILE,
        name: authorName,
      })
    );
    dispatch(closeModal());
  };

  const handleOpenLightBox = () => {
    if (!files?.length) {
      return;
    }

    dispatch(handleLightBox({ files: files, isOpen: true }));
  };

  const handleMessageToServiceOwner = async () => {
    setIsDisabled(true);

    const result = await request(
      {
        method: 'POST',
        url: '/v3/private/chat-room',
        data: {
          serviceId: selectedServiceId,
        },
      },
      dispatch,
      false,
      () => setIsDisabled(false)
    );

    if (result) {
      dispatch(persistChatRoom(result.chatRoom));
      dispatch(setActiveChatRoom(result.chatRoom.id));

      dispatch(
        setCurrentMainPanelSegment({
          segment: MainPanelSegments.MESSAGES_CENTER,
        })
      );
      dispatch(closeModal());
    }

    setIsDisabled(false);
  };

  return (
    <div
      className={`${styles.mainContainer} ${
        isCommentsOpen ? styles.mainContainer__hidden : ''
      }`}
    >
      {isLoading && (
        <Backdrop
          open
          sx={{ backdropFilter: 'blur(10px) sepia(0.3)', zIndex: '90000' }}
        >
          <CircleLoader width={150} />
        </Backdrop>
      )}
      <div className={styles.toolbar}>
        <div>
          <button
            onClick={() => handleSwitchComments(false)}
            className={`${styles.btnAdvert} ${
              !isCommentsOpen ? styles.btnAdvert_active : ''
            }`}
          >
            Skelbimas
          </button>
          <button
            onClick={() => handleSwitchComments(true)}
            disabled={isPreviewMode}
            className={`${styles.btnAdvert} ${
              isCommentsOpen ? styles.btnAdvert_active : ''
            }`}
          >
            Atsiliepimai
          </button>
        </div>
        <button disabled={isPreviewMode} className={styles.heartBtn}>
          <img src={heartIcon} alt='heart' /> {likeCount}
        </button>
      </div>
      <div
        className={isCommentsOpen ? styles.mobile_main_container_hidden : ''}
      >
        <h1>{title ?? 'Nėra pavadinimo'}</h1>
        <div className={styles.authorContainer}>
          <img
            src={authorImageUrl ?? defaultAvatar}
            alt='avatar'
            onClick={handleOpenUserProfile}
          />
          <div className={styles.authorText}>
            Įkelta <span>{authorName ?? 'Nėra vardo'}</span>
          </div>
          <div className={styles.authorReviewContainer}>
            <img src={reviewIcon} alt='reviewIcon' />
            <div>
              {authorRating} <span>({authorReviewCount})</span>
            </div>
          </div>
        </div>
        <div
          className={styles.serviceImageContainer}
          style={{
            background: `url(${
              serviceImageUrl ?? defaultServiceImage
            }) center center / cover no-repeat`,
          }}
        >
          <button onClick={handleOpenLightBox}>Žiūrėti visus darbus</button>
        </div>
        <div className={styles.itemContainer}>
          <div className={styles.item}>
            <div className={styles.itemPrice}>
              <span>{!_.isUndefined(price) ? price : ''}€</span> /{' '}
              {_.isUndefined(price) ? 'Kaina sutartinė' : 'Val.'}
            </div>
            {city && (
              <div className={styles.itemCity}>
                {city}{' '}
                {address && (
                  <span onClick={handleOpenMap}>(Atidaryti žemėlapį)</span>
                )}
              </div>
            )}
          </div>
          <div className={styles.item}>
            <button className={styles.btnVisit} disabled={user.id === authorId}>
              <img src={calendarTickIcon} alt='tick' />
              Rezervuoti vizitą
            </button>
          </div>
          <div className={styles.item}>
            <button
              className={styles.btnMessage}
              disabled={user.id === authorId || isDisabled}
              onClick={handleMessageToServiceOwner}
            >
              <img src={messageIcon} alt='messageIcon' />
              Parašyti žinutę
            </button>
            {phone && (
              <p className={styles.phoneText}>
                <img src={callingIcon} alt='phoneIcon' />
                {phone ?? ''}
              </p>
            )}
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.bottomContainer}>
          <div>
            <div className={styles.bottonContainerHeadline}>
              <div className={styles.greenBlob} />
              <h2>Aprašymas</h2>
            </div>
            <p className={styles.description}>
              {description ?? 'Nėra aprašymo'}
            </p>
          </div>
          <div>
            {Boolean(services?.length) && (
              <>
                <div className={styles.bottonContainerHeadline}>
                  <div className={styles.purpleBlob} />
                  <h2>Papildomos paslaugos</h2>
                </div>
                <div className={styles.extraServicesContainer}>
                  {(services ?? []).map((service, index) => (
                    <Fragment key={index}>
                      <p>
                        {
                          //@ts-ignore
                          AdditionalServicesText[Number(service)]
                        }
                      </p>
                      <div className={styles.dividerH} />
                    </Fragment>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ServiceModalCommentSegment: React.FC = () => {
  const [comment, setComment] = useState('');
  const [commentId, setCommentId] = useState<number>();
  const { comments, authorId } = useAppSelector((state) => state.serviceModal);
  const { id } = useAppSelector((state) => state.user);
  const [isDisabled, setIsDisabled] = useState(true);

  const dispatch = useAppDispatch();

  useMount(() => {
    if (authorId === id) {
      // cannot comment it own post
      setIsDisabled(true);
      return;
    }

    if (_.some(comments, (comment) => comment.ownerId === id)) {
      // user has already commented
      const oldComment = _.find(comments, (comment) => comment.ownerId === id);
      setComment(oldComment?.comment ?? '');
      setCommentId(oldComment?.id);
    }

    setIsDisabled(false);
  });

  const handleComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.currentTarget.value);
  };

  const updateComment = async () => {
    // update your comment or create new if not exists
    if (authorId === id) {
      return;
    }
    setIsDisabled(true);

    // send comment to server

    if (!comment) {
      setIsDisabled(false);
      return;
    }

    const result = await request(
      {
        method: commentId ? 'PATCH' : 'POST',
        url: `/v3/private/comment`,
        data: {
          userId: authorId,
          comment: comment ?? '',
          rating: 5,
          commentId: commentId,
        },
      },
      dispatch
    );

    setCommentId(result?.id);

    const index = comments?.findIndex((c) => c.ownerId === result?.userId);

    if (!_.isUndefined(index) && index > -1) {
      const updatedComments = _.cloneDeep(comments);

      if (updatedComments) {
        updatedComments[index].comment = comment;
        dispatch(setModalData({ comments: updatedComments }));
      }
    }

    dispatch(
      displayNotification({
        severity: 'success',
        message: `Komentaras ${commentId ? 'atnaujintas' : 'išsaugotas'}`,
      })
    );

    setIsDisabled(false);
  };

  return (
    <motion.div
      className={styles.sideContainer}
      initial={{
        opacity: 0,
        x: -50,
      }}
      animate={{
        opacity: 1,
        x: 0,
      }}
      exit={{
        opacity: 0,
        x: -50,
      }}
    >
      <div className={styles.bottonContainerHeadline}>
        <div className={styles.brownBlob} />
        <h2>Klientų komentarai</h2>
      </div>
      <textarea
        placeholder='Palikite komentarą, būkite malonūs'
        onChange={handleComment}
        value={comment}
        className={styles.commentInput}
        rows={6}
      />
      <button
        onClick={updateComment}
        disabled={isDisabled}
        className={styles.commentBtn}
      >
        Komentuoti
      </button>
      <div className={styles.otherCommentContainer}>
        {_.map(comments, (comment, index) => (
          <CommentCard
            key={index}
            authorImageUrl={comment.authorImageUrl ?? defaultAvatar}
            authorName={comment.authorName}
            updatedOn={`prieš ${Math.floor(
              (new Date().getTime() - new Date(comment.updatedOn).getTime()) /
                (1000 * 60 * 60 * 24)
            )} d.`}
            rating={comment.rating}
            comment={comment.comment}
            id={comment.ownerId}
          />
        ))}
      </div>
    </motion.div>
  );
};

type CommentCardProps = {
  authorImageUrl: string;
  authorName: string;
  updatedOn: string;
  rating: number;
  comment: string;
  id: number;
};

const CommentCard: React.FC<CommentCardProps> = ({
  authorImageUrl,
  authorName,
  updatedOn,
  rating,
  comment,
}) => {
  return (
    <div className={styles.commentCard}>
      <div className={styles.commentCardHeadline}>
        <div>
          <img
            className={styles.commentCardAvatar}
            src={authorImageUrl}
            alt='avatar'
          />
          <div className={styles.commentCardAuthorName}>{authorName}</div>
        </div>
        <div>
          <div className={styles.commentCardUpdatedOn}>{updatedOn}</div>
          <div className={styles.commentCardRating}>
            <img src={reviewIcon} alt='review' /> <span>{rating}</span>
          </div>
        </div>
      </div>
      <div className={styles.commentCardComment}>{comment}</div>
    </div>
  );
};
