import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  HttpStatusCode,
} from 'axios';
import {
  displayNotification,
  setGlobalLoading,
  setInitialized,
} from '../reducers/GlobalReducer';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { resetUserData, setUserData } from '../reducers/UserReducer';
import { purgeData, useAppDispatch } from '../GlobalStore';
import _ from 'lodash';
import { useMount } from 'react-use';
import { useNavigate } from 'react-router-dom';
import {
  NotificationData,
  NotificationItem,
  setData,
} from '../reducers/NotificationReducer';
import { setData as setMessageCenterData } from '../reducers/MessageCenterReducer';

import { NotificationType } from '@web-app/common';
import { useState } from 'react';

// prod has : '/api'
// local has : '/'

const client = axios.create({
  baseURL: process.env.REACT_APP_REQUEST_API ?? '/api',
  withCredentials: true,
});
const request = function <T = any>(
  options: AxiosRequestConfig,
  dispatch?: ThunkDispatch<any, any, any>,
  useLoader = false,
  errorCallBack?: () => void,
  isFileDownload = '', // set file name for download,
  retries: number = 0
): Promise<T> {
  const onSuccess = function (response: AxiosResponse<any>) {
    if (isFileDownload.length > 0) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('download', isFileDownload); // You might need to specify a filename here
      document.body.appendChild(link);
      link.click();

      if (link.parentNode) link.parentNode.removeChild(link);
    }

    if (!_.isEmpty(response?.data?.message) && dispatch) {
      dispatch(
        displayNotification({
          severity: 'info',
          message: response.data.message,
        })
      );
    }
    return response.data;
  };

  const onError = async function (error: AxiosError<any>) {
    console.log(error?.response?.status, error?.message);
    if (retries) {
      if (retries > 0) {
        await setTimeout(() => {
          // retry
        }, 1500);

        return request(
          options,
          dispatch,
          useLoader,
          errorCallBack,
          isFileDownload,
          retries - 1
        );
      }
    }

    switch (error?.response?.status) {
      case HttpStatusCode.Unauthorized:
        // logout user
        if (dispatch) {
          dispatch(resetUserData());
        }
        purgeData();
        window.location.href = '/login';
        break;
    }

    if (dispatch) {
      dispatch(
        displayNotification({
          severity: 'error',
          message: error?.response?.data?.message || error?.message,
        })
      );

      if (errorCallBack) {
        errorCallBack();
      }
      return Promise.race([]);
    }

    return Promise.reject<AxiosError>(error?.response || error?.message);
  };

  const onFinally = function () {
    if (useLoader && dispatch) dispatch(setGlobalLoading(false));
  };

  if (useLoader && dispatch) dispatch(setGlobalLoading(true));
  return client(options).then(onSuccess).catch(onError).finally(onFinally);
};

export default request;

export function handleScrollTo(
  elementAnchorId: string,
  isOverflownElement?: boolean,
  isSmooth = true
) {
  const element = document.getElementById(elementAnchorId);

  if (isOverflownElement && element) {
    let parent = element.parentElement;

    // Find the closest ancestor that has overflow set
    while (parent) {
      const overflowY = window.getComputedStyle(parent).overflowY;
      if (overflowY === 'auto' || overflowY === 'scroll') {
        parent.scrollTo({
          top: element.offsetTop - parent.offsetTop,
          behavior: isSmooth ? 'smooth' : 'instant',
        });
        return;
      }
      parent = parent.parentElement;
    }
  }

  if (element) {
    element.scrollIntoView({
      behavior: isSmooth ? 'smooth' : 'instant',
    });
  }
}

export const useInitiateHelmet = () => {
  const [helmetInitialized, setHelmetInitialized] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useMount(async () => {
    setHelmetInitialized(false);
    dispatch(setGlobalLoading(true));

    const userData = await request(
      {
        method: 'GET',
        url: '/v3/private/user/data',
      },
      dispatch,
      false,
      () => {
        // logout invalid user
        purgeData();
        navigate('/');
      },
      undefined,
      5
    );

    const buildUserData = {
      id: userData.id,
      email: userData.email,
      permissions: userData.permissions,
      avatarFileName: userData.avatarFileName,
      name: userData.name,
      rating: userData.rating,
      ratingCount: userData.ratingCount,
    };
    dispatch(setUserData(buildUserData));

    const notificationData = await request<{
      notifications: {
        id: number;
        userId: number;
        data: NotificationData;
        notificationType: NotificationType;
        createdOn: Date;
      }[];
    }>(
      {
        method: 'GET',
        url: '/v3/private/notifications',
        params: {
          limit: 3,
        },
      },
      dispatch,
      false,
      () => {
        dispatch(setGlobalLoading(false));
      }
    );

    if (notificationData.notifications) {
      // can be message type notifications
      const messageNotifications: NotificationItem[] = [];
      const notifications: NotificationItem[] = [];

      for (const notification of notificationData.notifications) {
        if (notification.notificationType === NotificationType.UNREAD_MESSAGE) {
          messageNotifications.push({
            ...notification,
            createdOn: notification.createdOn.toString(),
          });
        } else {
          notifications.push({
            ...notification,
            createdOn: notification.createdOn.toString(),
          });
        }
      }

      dispatch(
        setData({
          notifications: notifications,
          messageNotifications: messageNotifications,
        })
      );
    }

    const chatRoomsData = await request({
      method: 'GET',
      url: '/v3/private/chat-rooms',
      params: {
        limit: 3,
      },
    });

    if (chatRoomsData.chatRooms) {
      dispatch(setMessageCenterData({ chatRooms: chatRoomsData.chatRooms }));
    }

    dispatch(setInitialized(true));
    dispatch(setGlobalLoading(false));
    setHelmetInitialized(true);
  });

  return [helmetInitialized];
};

export function trimString(string: string, length: number) {
  return string.length > length ? string.substring(0, length) + '...' : string;
}

export function trimTextWithHtml(input: string, limit: number) {
  let length = 0;
  let trimmedText = '';
  const tagStack = [];
  const tagRegex = /<\/?[^>]+(>|$)/g;
  let lastIndex = 0;

  let match;

  while ((match = tagRegex.exec(input)) !== null) {
    // Text portion before the tag
    const beforeTag = input.slice(lastIndex, match.index);

    // Add text to the trimmed result while checking the length limit
    if (length + beforeTag.length > limit) {
      trimmedText += beforeTag.slice(0, limit - length) + '...';
      length = limit;
      break;
    } else {
      trimmedText += beforeTag;
      length += beforeTag.length;
    }

    // Add the tag to the trimmed result
    trimmedText += match[0];

    // Track open and close tags
    if (!match[0].startsWith('</')) {
      const tagName = match[0].match(/<\s*([^\s/>]+)/)?.[1];
      tagStack.push(tagName);
    } else {
      tagStack.pop();
    }

    lastIndex = match.index + match[0].length;
  }

  // Handle any remaining text after the last tag
  if (length < limit && lastIndex < input.length) {
    const remainingText = input.slice(lastIndex, limit - length + lastIndex);
    trimmedText += remainingText;
    length += remainingText.length;

    if (length === limit) {
      trimmedText += '...';
    }
  }

  // Close any unclosed tags
  while (tagStack.length > 0) {
    trimmedText += `</${tagStack.pop()}>`;
  }

  return trimmedText;
}
