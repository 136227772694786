import { useState } from 'react';
import { Input } from '../../../../components/Input/Input';
import styles from './styles/DeactivateAccount.module.scss';
import request from '../../../../global/helpers/utils';
import { useAppDispatch } from '../../../../global/GlobalStore';

const DeactivateAccount: React.FC = () => {
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  const handleSubmit = async () => {
    setIsDisabled(true);

    await request(
      {
        method: 'POST',
        url: '/v3/auth/deactivate-account',
        data: {
          email,
        },
      },
      dispatch,
      false,
      () => setIsDisabled(true)
    );

    setIsDisabled(false);
  };

  return (
    <div className={styles.container}>
      <p className={styles.headline}>Ištrinti paskyrą</p>
      <div className={styles.spacer} />
      <p className={styles.underline}>
        Visam laikui ištrinti savo paskyrą ir visus su ja susijusius duomenis -
        tai mūsų atliekamas rankinis procesas.
      </p>
      <Input
        label='Jūsų el. paštas'
        value={email}
        type='email'
        onChange={(value) => setEmail(value)}
      />
      <button
        className={styles.submitBtn}
        disabled={isDisabled}
        onClick={handleSubmit}
      >
        Visam laikui ištrinti paskyrą
      </button>
    </div>
  );
};

export default DeactivateAccount;
