import CommentsPanel from './CommentsPanel';
import ProfileInfo from './ProfileInfo';
import styles from './styles/Profile.module.scss';
import UserServicesList from './UserServicesList';

const Profile: React.FC = () => {
  return (
    <div className={styles.profileContainer}>
      <div className={styles.leftCard}>
        <ProfileInfo />
        <UserServicesList />
      </div>
      <div className={styles.rightCard}>
        <CommentsPanel />
      </div>
    </div>
  );
};

export default Profile;
