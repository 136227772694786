import { useCallback, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../global/GlobalStore';
import {
  Message,
  setActiveChatRoom,
  setForceRefresh,
  User,
} from '../../../global/reducers/MessageCenterReducer';
import styles from './styles/MessagesSearch.module.scss';
import { motion } from 'framer-motion';

import searchIcon from '../../../assets/icons/search-normal.svg';

import avatarPlaceHolderIcon from '../../../assets/icons/avatar-placeholder.svg';
import _ from 'lodash';
import { trimTextWithHtml } from '../../../global/helpers/utils';

type MessagesSearchProps = {
  burgerHandler?: () => void;
};

const MessagesSearch: React.FC<MessagesSearchProps> = ({ burgerHandler }) => {
  const { chatRooms, forceRefresh } = useAppSelector(
    (state) => state.messageCenter
  );
  const userData = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const [searchResults, setSearchResults] = useState(chatRooms);

  const searchedItems = useMemo(() => {
    if (forceRefresh) {
      dispatch(setForceRefresh(false));
    }

    return (
      <div className={styles.chatRoomsContainer}>
        {searchResults.map((chatRoom, index) => (
          <ChatRoomItem
            user={chatRoom?.users?.find((u) => u.id !== userData.id)}
            {...chatRoom}
            lastSeenMessageId={chatRoom.lastSeenMessageId}
            previewLastMessage={chatRoom.previewLastMessage}
            key={index}
            burgerhandler={burgerHandler}
          />
        ))}
      </div>
    );
  }, [searchResults, userData.id, chatRooms, forceRefresh, dispatch]);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const searchValue = e.target.value;

      const results = chatRooms.filter((chatRoom) => {
        const users = chatRoom.users.map((user) => _.lowerCase(user.name));
        const title = _.lowerCase(chatRoom.title) || '';

        return (
          users.some((user) => user.includes(_.lowerCase(searchValue))) ||
          title.includes(_.lowerCase(searchValue))
        );
      });

      setSearchResults(results);
    },
    [chatRooms]
  );

  return (
    <div className={styles.searchContainer}>
      {searchedItems}
      <div className={styles.searchInput}>
        <img alt='zoom-icon' src={searchIcon} />
        <input onChange={handleSearch} placeholder='Ieškoti pokalbį' />
      </div>
    </div>
  );
};
export default MessagesSearch;

type ChatRoomItemProps = {
  id: number;
  title?: string;
  createdOn: string;
  user?: User;
  previewLastMessage?: Message;
  lastSeenMessageId?: number;
  burgerhandler?: () => void;
};

const ChatRoomItem: React.FC<ChatRoomItemProps> = ({
  id,
  title,
  user,
  previewLastMessage,
  lastSeenMessageId,
  createdOn,
  burgerhandler,
}) => {
  const { activeChatRoom } = useAppSelector((state) => state.messageCenter);
  const dispatch = useAppDispatch();

  const handleSelectChatRoom = useCallback(() => {
    if (activeChatRoom?.id === id) return;

    dispatch(setActiveChatRoom(id));

    if (burgerhandler) {
      burgerhandler();
    }
  }, [id, activeChatRoom, dispatch, burgerhandler]);

  return (
    <motion.div
      className={`${styles.messageItem} ${
        activeChatRoom?.id === id ? styles.messageItem_active : ''
      }`}
      onClick={handleSelectChatRoom}
    >
      <img
        className={styles.messageAvatarIcon}
        src={user?.avatarUrl ?? avatarPlaceHolderIcon}
        alt='avatar'
      />
      <div className={styles.messageTextContainer}>
        <p className={styles.upperText}>
          {title ?? user?.name}
          <span>
            {new Date(
              previewLastMessage?.createdOn ?? createdOn
            ).toLocaleTimeString('lt-LT', {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </span>
        </p>
        <p className={styles.lowerText}>
          <div
            dangerouslySetInnerHTML={{
              __html: trimTextWithHtml(previewLastMessage?.message ?? ' ', 25),
            }}
          />
          {}
        </p>
      </div>
      <div className={styles.messageNotificatinoContainer}>
        <div
          className={styles.messageNotificationIcon}
          style={{
            backgroundColor:
              (lastSeenMessageId ?? 0) < (previewLastMessage?.id ?? 0) &&
              lastSeenMessageId !== undefined &&
              previewLastMessage !== undefined
                ? '#5D55FA'
                : '#E9E9E9',
          }}
        />
      </div>
    </motion.div>
  );
};
