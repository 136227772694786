import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

export enum UserStatus {
  ACTIVE = 1,
  AWAY = 2,
  BUSY = 3,
  OFFLINE = 4,
}

export interface MessageCenterState {
  chatRooms: ChatRoom[];

  // for loading chat rooms and their messages
  isLoading?: boolean;
  initialized?: boolean;
  isLoadingMessages?: boolean;
  forceRefresh?: boolean;

  // active chat room data
  activeChatRoom?: ChatRoom;
}

export interface ChatRoom {
  id: number;
  users: User[];
  createdOn: string; // redux does not support Date object
  title?: string;

  previewLastMessage?: Message;
  lastSeenMessageId?: number;

  messages?: Message[]; // available only for active chat room if it's loaded
  isAlreadyFullyLoaded?: boolean;
}

export interface User {
  id: number;
  name: string;
  status: UserStatus;
  avatarUrl?: string;
  rating?: number;
  ratingCount?: number;
}

export interface Message {
  id: number;
  userId: number;
  chatRoomId: number;

  message: string;
  createdOn: string; // redux does not support Date object

  metaData?: {
    replyTo?: {
      messageId: number;
    };
    attachedFiles?: {
      mimeType: string;
      fileName: string;
      originalFileName?: string;
    }[];
  };
}

const initialState: MessageCenterState = {
  chatRooms: [],
};

export const messageCenterSlice = createSlice({
  name: 'messageCenter',
  initialState: initialState,
  reducers: {
    setData: (state, action: PayloadAction<Partial<MessageCenterState>>) => {
      state = { ...state, ...action.payload };

      return state;
    },
    persistChatRoom: (state, action: PayloadAction<ChatRoom>) => {
      const chatRoomIndex = state.chatRooms.findIndex(
        (c) => c.id === action.payload.id
      );

      if (chatRoomIndex > -1) {
        state.chatRooms[chatRoomIndex] = action.payload;
      } else {
        state.chatRooms.push(action.payload);
      }

      return state;
    },
    setActiveChatRoom: (state, action: PayloadAction<number>) => {
      state.activeChatRoom =
        state.chatRooms.find((c) => c.id === action.payload) ?? undefined;
      return state;
    },

    setChatRoomMessages: (
      state,
      action: PayloadAction<{ chatRoomId: number; messages: Message[] }>
    ) => {
      // used in chat to initialize messages
      const chatRoom = state.chatRooms.find(
        (c) => c.id === action.payload.chatRoomId
      );

      if (chatRoom) {
        chatRoom.messages = action.payload.messages;

        chatRoom.isAlreadyFullyLoaded = true;

        if (action.payload.messages.length) {
          chatRoom.previewLastMessage =
            action.payload.messages[action.payload.messages.length - 1];
        }
      }

      if (state.activeChatRoom?.id === action.payload.chatRoomId) {
        const activeChatRoom = _.cloneDeep(state.activeChatRoom);
        activeChatRoom.messages = action.payload.messages;

        if (action.payload.messages.length) {
          activeChatRoom.previewLastMessage =
            action.payload.messages[action.payload.messages.length - 1];

          activeChatRoom.lastSeenMessageId =
            action.payload.messages[action.payload.messages.length - 1].id;
        }

        state.activeChatRoom = activeChatRoom;
      }

      return state;
    },

    // Chat Actions
    addMessage: (
      state,
      action: PayloadAction<{ chatRoomId: number; message: Message }>
    ) => {
      const chatRoom = state.chatRooms.find(
        (c) => c.id === action.payload.chatRoomId
      );

      if (chatRoom) {
        chatRoom.messages?.push(action.payload.message);
        chatRoom.previewLastMessage = action.payload.message;
      }

      if (state.activeChatRoom?.id === action.payload.chatRoomId) {
        state.activeChatRoom.messages?.push(action.payload.message);
        state.activeChatRoom.previewLastMessage = action.payload.message;
      }

      return state;
    },
    updateMessage: (
      state,
      action: PayloadAction<{ chatRoomId: number; message: Partial<Message> }>
    ) => {
      const chatRoom = state.chatRooms.find(
        (c) => c.id === action.payload.chatRoomId
      );

      if (chatRoom) {
        const messageIndex =
          chatRoom.messages?.findIndex(
            (m) => m.id === action.payload.message.id
          ) ?? -1;

        if (messageIndex > -1) {
          chatRoom.messages![messageIndex] = {
            ...chatRoom.messages![messageIndex],
            ...action.payload.message,
          };
        }
      }

      if (state.activeChatRoom?.id === action.payload.chatRoomId) {
        const messageIndex =
          state.activeChatRoom.messages?.findIndex(
            (m) => m.id === action.payload.message.id
          ) ?? -1;

        if (messageIndex > -1) {
          state.activeChatRoom.messages![messageIndex] = {
            ...state.activeChatRoom.messages![messageIndex],
            ...action.payload.message,
          };
        }
      }

      return state;
    },
    seenMessage: (
      state,
      action: PayloadAction<{ chatRoomId: number; messageId: number }>
    ) => {
      const chatRoom = state.chatRooms.find(
        (c) => c.id === action.payload.chatRoomId
      );

      if (chatRoom) {
        chatRoom.lastSeenMessageId = action.payload.messageId;

        const clone = _.cloneDeep(state.chatRooms);
        state.chatRooms = clone;
      }

      if (state.activeChatRoom?.id === action.payload.chatRoomId) {
        state.activeChatRoom.lastSeenMessageId = action.payload.messageId;
      }

      state.forceRefresh = true;

      return state;
    },
    setForceRefresh: (state, action: PayloadAction<boolean>) => {
      state.forceRefresh = action?.payload ?? false;
      return state;
    },
  },
});

export default messageCenterSlice.reducer;
export const {
  setData,
  persistChatRoom,
  setActiveChatRoom,

  setChatRoomMessages,
  addMessage,
  updateMessage,
  seenMessage,
  setForceRefresh,
} = messageCenterSlice.actions;
