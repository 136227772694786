import { useMount } from 'react-use';
import MessagesSearch from './MessagesSearch';
import MessageWindow from './MessageWindow';
import styles from './styles/MessagesCenter.module.scss';
import { useAppDispatch, useAppSelector } from '../../../global/GlobalStore';
import { Skeleton } from '@mui/material';
import { setData } from '../../../global/reducers/MessageCenterReducer';
import request from '../../../global/helpers/utils';
import BurgerButton from '../../../components/Burger/BurgerButton';
import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

const MessagesCenter: React.FC = () => {
  const { initialized, isLoading } = useAppSelector(
    (state) => state.messageCenter
  );

  const [isBurgerOpen, setIsBurgerOpen] = useState(true);

  const dispatch = useAppDispatch();

  useMount(async () => {
    if (initialized) return;
    // initialize all the chat rooms metadata

    const data = await request(
      {
        method: 'GET',
        url: '/v3/private/chat-rooms',
      },
      dispatch,
      false,
      () => dispatch(setData({ isLoading: false }))
    );
    dispatch(setData({ chatRooms: data.chatRooms }));

    dispatch(setData({ initialized: true, isLoading: false }));
  });

  const handleBurgerClick = () => {
    setIsBurgerOpen(!isBurgerOpen);
  };

  if (!initialized || isLoading) {
    return (
      <div className={styles.centerContainer}>
        <div className={styles.leftCenterContainer}>
          <Skeleton />
          <Skeleton />
          <br />
          <Skeleton />
          <Skeleton />
          <br />
          <Skeleton />
          <Skeleton />
          <br />
          <Skeleton />
          <Skeleton />
          <br />
          <Skeleton />
          <Skeleton />
          <br />
          <Skeleton />
          <Skeleton />
        </div>
        <div className={styles.rightCenterContainer}></div>
      </div>
    );
  }

  return (
    <div className={styles.centerContainer}>
      <AnimatePresence mode='wait'>
        <div className={styles.leftCenterContainer}>
          <div className={styles.mobileVisibleOnly}>
            <BurgerButton
              burgerStyleType='cross-top'
              onClick={handleBurgerClick}
              isOpen={isBurgerOpen}
            />
            {isBurgerOpen && (
              <motion.div
                initial={{
                  opacity: 0,
                  y: -100,
                  scale: 0.4,
                }}
                animate={{
                  opacity: 1,
                  y: 0,
                  scale: 1,
                }}
                transition={{
                  duration: 0.2,
                }}
                className={`${
                  isBurgerOpen ? styles.leftCenterContainer_mob_active : ''
                } ${styles.passThroughContainer}`}
                style={{
                  display: isBurgerOpen ? 'block' : '',
                }}
              >
                <MessagesSearch burgerHandler={handleBurgerClick} />
              </motion.div>
            )}
          </div>
          <div className={styles.visibleOnlyDesktop}>
            <MessagesSearch />
          </div>
        </div>
      </AnimatePresence>
      <div className={styles.rightCenterContainer}>
        <MessageWindow />
      </div>
    </div>
  );
};

export default MessagesCenter;
