import { useMemo } from 'react';
import { MainPanelSegments } from '../../../global/helpers/constants';
import { useAppSelector } from '../../../global/GlobalStore';
import Helm from '../helm/Helm';
import ProfileSettings from '../profile/edit/ProfileSettings';
import FaqPanel from '../faq-panel/FaqPanel';
import UserServices from '../services-panel/services-list/UserServices';
import ServiceAdjustPanel from '../services-panel/service/ServiceAdjustPanel';
import RememberedServices from '../services-panel/remembered-services-list/RememberedServices';
import Profile from '../profile/view/Profile';
import MessagesCenter from '../messages/MessagesCenter';
import NotificationsPanel from '../notifications/NotificationsPanel';
import { socket, SocketContext } from '../../../global/helpers/socket_context';

const MainPanel: React.FC = () => {
  const { currentMainPanelSegment } = useAppSelector((state) => state.global);

  const segmentElement = useMemo(() => {
    switch (currentMainPanelSegment) {
      case MainPanelSegments.PROFILE_SETTINGS:
        return <ProfileSettings />;
      case MainPanelSegments.FAQ:
        return <FaqPanel />;
      case MainPanelSegments.USER_SERVICES:
        return <UserServices />;
      case MainPanelSegments.ADJUST_SERVICE:
        return <ServiceAdjustPanel />;
      case MainPanelSegments.SERVICES_IN_MEMORY:
        return <RememberedServices />;
      case MainPanelSegments.PROFILE:
        return <Profile />;
      case MainPanelSegments.MESSAGES_CENTER:
        return <MessagesCenter />;
      case MainPanelSegments.NOTIFICATIONS:
        return <NotificationsPanel />;
      default:
        return null;
    }
  }, [currentMainPanelSegment]);

  return (
    <SocketContext.Provider value={socket}>
      <Helm>{segmentElement}</Helm>
    </SocketContext.Provider>
  );
};

export default MainPanel;
