import styles from '../LoginPage.module.scss';

import mail_icon from '../../../../assets/icons/sms.svg';
import { useEffect, useState, useCallback } from 'react';
import { useAppDispatch } from '../../../../global/GlobalStore';
import CircleLoader from '../../../../components/Loader/CircleLoader';
import request from '../../../../global/helpers/utils';
import { displayNotification } from '../../../../global/reducers/GlobalReducer';
import _ from 'lodash';

const ForgotPasswordForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const [count, isDisabled, resetCountdown] = useCountdown(60, 1000);

  const handleSubmit = useCallback(async () => {
    // Add your login logic here
    if (isLoading || isDisabled || _.isEmpty(email)) return;

    setIsLoading(true);
    try {
      await request(
        {
          method: 'POST',
          url: '/auth/forgot-password',
          data: {
            email: email,
          },
        },
        dispatch,
        false,
        () => setIsLoading(false)
      );

      dispatch(
        displayNotification({
          message: 'Slaptažodžio atstatymo nuoroda išsiųsta į jūsų el. paštą',
          severity: 'success',
        })
      );

      resetCountdown();
    } catch (e) {
      // indicate somewhere is error
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, email, isLoading, isDisabled, resetCountdown]);

  return (
    <div>
      <p className={styles.title}>Priminti slaptažodį</p>
      <p className={styles.titleUnderline}>
        Įveskite el. paštą susietą su paskyrą. <br />
        Mes jums atsiųsime laišką su slaptažodžio atstatymo nuoroda.
      </p>
      <div className={styles.formContainer}>
        <div className={styles.basicInputBox}>
          <div
            style={{ backgroundImage: `url(${mail_icon})` }}
            className={styles.inputIcon}
          />
          <input
            className={styles.basicInput}
            placeholder='Jūsų el. paštas'
            type='email'
            id='email'
            disabled={isLoading || isDisabled}
            value={email}
            onChange={handleEmailChange}
          />
        </div>
        <button
          type='submit'
          onClick={handleSubmit}
          className={styles.submitBtn}
          disabled={isLoading || isDisabled}
        >
          {isLoading ? (
            <CircleLoader width={50} />
          ) : (
            <>
              {count > 0
                ? `Prašome palaukti ${count}s, kol galėsite išsiųsti naują nuorodą`
                : 'Išsiųsti atnaujinimo nuorodą'}
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;

const useCountdown = (
  initialCount: number,
  interval: number
): [number, boolean, () => void] => {
  const [count, setCount] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);

  const resetCountdown = () => {
    setCount(initialCount);
    setIsDisabled(true);
  };

  useEffect(() => {
    if (count > 0) {
      const timer = setTimeout(() => {
        setCount(count - 1);
      }, interval);
      return () => clearTimeout(timer);
    } else {
      setIsDisabled(false);
    }
  }, [count, interval]);

  return [count, isDisabled, resetCountdown];
};
