import { useMemo } from 'react';
import LoginForm from './components/LoginForm';
import ValidateEmailForm from './components/ValidateEmailForm';
import RegisterForm from './components/RegisterForm';

import styles from './LoginPage.module.scss';

import logo_big from '../../../assets/logo_big.svg';
import { useAppDispatch, useAppSelector } from '../../../global/GlobalStore';
import {
  closeNotification,
  setAuthenticationData,
} from '../../../global/reducers/GlobalReducer';
import { Alert, Snackbar } from '@mui/material';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import ResetForgottenPasswordForm from './components/ResetForgottenPasswordForm';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

export enum FormSegment {
  LOGIN,
  REGISTER,
  VERIFY_EMAIL,
  FORGOTTEN_PASSWORD,
  RESET_FORGOTTEN_PASSWORD,
}

const LoginPage: React.FC = () => {
  const { authentication, notification } = useAppSelector(
    (state) => state.global
  );
  const dispatch = useAppDispatch();
  const location = useLocation();

  const renderForm = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    const resetToken = queryParams.get('reset');

    if (!_.isEmpty(resetToken) && resetToken?.length) {
      dispatch(
        setAuthenticationData({ segment: FormSegment.RESET_FORGOTTEN_PASSWORD })
      );
      return <ResetForgottenPasswordForm token={resetToken} />;
    }

    switch (authentication?.segment) {
      case FormSegment.LOGIN:
        return <LoginForm />;
      case FormSegment.REGISTER:
        return <RegisterForm />;
      case FormSegment.VERIFY_EMAIL:
        return <ValidateEmailForm />;
      case FormSegment.FORGOTTEN_PASSWORD:
        return <ForgotPasswordForm />;
      default:
        return <LoginForm />;
    }
  }, [authentication?.segment, location.search, dispatch]);

  const renderButtons = useMemo(() => {
    switch (authentication?.segment) {
      case FormSegment.LOGIN:
        return (
          <>
            {/* <div className={styles.simpleBox}>
              <button>Prisijungti su Google</button>
            </div> */}
            <div className={styles.simpleBox} style={{ display: 'flex' }}>
              <button className={styles.privacyPolicyBtn}>
                Privatumo Politika
              </button>
            </div>
            <div className={styles.simpleBox}>
              <p className={styles.titleUnderline}>
                Neturite paskyros?{' '}
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    dispatch(
                      setAuthenticationData({ segment: FormSegment.REGISTER })
                    )
                  }
                >
                  Užsiregistruokite
                </span>
              </p>
            </div>
            <div className={styles.simpleBox}>
              <p className={styles.titleUnderline}>
                Pamiršai slaptažodį?{' '}
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    dispatch(
                      setAuthenticationData({
                        segment: FormSegment.FORGOTTEN_PASSWORD,
                      })
                    )
                  }
                >
                  Priminti slaptažodį
                </span>
              </p>
            </div>
          </>
        );
      case FormSegment.REGISTER:
        return (
          <>
            {/* <div className={styles.simpleBox}>
              <button>Registruotis su Google</button>
            </div> */}
            <div className={styles.simpleBox} style={{ display: 'flex' }}>
              <button className={styles.privacyPolicyBtn}>
                Privatumo Politika
              </button>
            </div>
            <div className={styles.simpleBox}>
              <p className={styles.titleUnderline}>
                Jau turite paskyrą?{' '}
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    dispatch(
                      setAuthenticationData({ segment: FormSegment.LOGIN })
                    )
                  }
                >
                  Prisijunkite
                </span>
              </p>
            </div>
          </>
        );
      default:
        return null;
    }
  }, [authentication?.segment, dispatch, location.search]);

  return (
    <>
      <div className={styles.loginBackground}>
        <div className={styles.loginBox}>
          <div className={styles.loginForm}>
            <div className={styles.logoContainer}>
              <img src={logo_big} alt='logo' />
            </div>
            {renderForm}
          </div>
          {renderButtons}
        </div>
      </div>

      <Snackbar
        sx={{
          zIndex: 9999,
        }}
        open={notification.isOpen}
        autoHideDuration={5000}
        onClose={() => dispatch(closeNotification())}
      >
        <Alert
          severity={notification?.severity || 'info'}
          variant='standard'
          onClose={() => dispatch(closeNotification())}
        >
          {notification?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default LoginPage;
